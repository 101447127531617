/* global pino */
import * as THREE from 'three'

import Config from '../../data/config'

// Class that creates and updates the main camera
export default class Camera {
  constructor (renderer) {
    const width = renderer.domElement.width
    const height = renderer.domElement.height

    // Create and position a Perspective Camera
    this.threeCamera = new THREE.PerspectiveCamera(Config.camera.fov, width / height, Config.camera.near, Config.camera.far)
    this.threeCamera.position.set(Config.camera.posX, Config.camera.posY, Config.camera.posZ)

    // Initial sizing
    this.updateSize(renderer)
  }

  activate (bookmark) {
    if (bookmark.data) {
      this.threeCamera.fov = bookmark.data.fov
      this.threeCamera.near = bookmark.data.near
      this.threeCamera.far = bookmark.data.far
    }
  }

  updateSize (renderer) {
    let aspect = renderer.domElement.width / renderer.domElement.height
    this.updateAspect(aspect)
  }

  updateAspect (aspect) {
    // Update camera aspect ratio with window aspect ratio
    this.threeCamera.aspect = aspect

    // Always call updateProjectionMatrix on camera change
    this.threeCamera.updateProjectionMatrix()
  }
}
