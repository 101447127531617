import * as THREE from 'three'
import { EventEmitter } from 'events'

export default class PickHelper {
  constructor (container, scene, camera) {
    this.raycaster = new THREE.Raycaster()
    this.emitter = new EventEmitter()
    this.pickedObject = null
    this.pickedObjectSavedColor = 0

    this.mouse = new THREE.Vector2()
    this.selectedObjects = []

    this.container = container
    this.scene = scene
    this.camera = camera

    this.active = false

    this.attach()
  }

  attach () {
    window.addEventListener('dblclick', (event) => {
      if(event.target.nodeName !== "CANVAS") return
      this.onPick(event)
    })
  }

  dettach () {
    window.removeEventListener('dblclick')
  }

  onPick (event) {
    let rect = this.container.getBoundingClientRect()

    this.mouse.x = ((event.clientX - rect.x) / rect.width) * 2 - 1
    this.mouse.y = -((event.clientY - rect.y) / rect.height) * 2 + 1

    this.raycaster.setFromCamera(this.mouse, this.camera.threeCamera)
    const intersectedObjects = this.raycaster.intersectObjects(this.scene.children, true)
    if (intersectedObjects.length) {

      // pick the first object. It's the closest one
      let pickedObject = intersectedObjects[0].object
      // const multiSelect = event.ctrlKey && event.shiftKey
      // console.log(multiSelect)
      this.emitter.emit('pickobject', pickedObject)
    }
  }
}