import { MathUtils } from '../utils/math.js'

function Sequence () {
  this.name = 'New Sequence'
  this.type = 'Sequence'
  this.uuid = MathUtils.generateUUID()
}

Sequence.prototype = Object.assign({

  constructor: Sequence,

  isSequence: true,

  toJSON: function ( meta ) {

    const output = {}
    const object = {}

    output.metadata = {
      version: 0.1,
      type: this.type,
      generator: 'Sequence.toJSON'
    }

    object.uuid = this.uuid
    object.type = this.type
    object.name = this.name

    output.object = object

    return output
  }

})

export { Sequence }
