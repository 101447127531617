import { Sequences } from './Sequences.js'

function Modules () {
  this.name = ''

  this.children = [
    new Sequences()
  ]
}

Modules.prototype = Object.assign({

  constructor: Modules,

  isModules: true,

  toNode () {
    return {
      type: 'ModuleBaseGroup',
      name: 'Modules',
      children: this.children
    }
  }

})

export { Modules }
