/* global pino */
import * as THREE from 'three'

import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry.js'
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js'

import { EventEmitter } from 'events'

import MeshHelper from '../helpers/meshHelper'
import PickHelper from '../helpers/picker'
import HighlightHelper from '../helpers/highlight'
// import Material from '../helpers/Material'
// import Helpers from '../../utils/helpers'
import Model from '../model/model'
import Texture from '../model/texture'
import Settings from '../helpers/settings'
import Animation from '../components/animation'
import { Bookmark } from '../components/bookmark'

import { Modules } from '../../modules/index.js'

// Manages all Setting Interactions
export default class Gui {
  constructor(main, logger) {
    this.emitter = new EventEmitter()
    this.logger = logger
    this.main = main
    this.camera = main.camera // threeCamera
    this.controls = main.controls
    this.controls.emitter.on('controlsleep', (event) => {
      this.checkBookmarkActiveState()
    })

    this.light = main.light
    this.renderer = main.renderer
    this.manager = this.setupLoadingManager()

    this.model = null
    this.texture = new Texture()

    this.settings = new Settings()
    this.animation = new Animation()

    this.meshHelper = new MeshHelper(this.main.scene, this.texture.textures)

    this.picker = new PickHelper(
      this.main.container,
      this.main.scene,
      this.camera
    )
    this.highlighter = new HighlightHelper(
      this.main.container,
      this.main.scene,
      this.camera
    )
    this.selectedObj = null
    this._selectionOutline = null
    this.picker.emitter.on('pickobject', (obj) => {
      if (!this.main.runsInEditorMode) return
      // if(multiSelect) this._selectedObj = [...this._selectedObj, obj]  if multiple selection, push picked to array of obj
      if (!obj.name) return
      if (obj.name.toLowerCase().includes('helper')) return // exclude all kind of helpers : camera, controls etc...
      if (obj.type === 'Line') return
      if (obj.name === 'TransformControls') return
      this._selectedObj = obj
      this.onObjectSelected(obj)
    })
    this.highlighter.emitter.on('highlightobject', (obj) => {
      if (obj === undefined) {
        this.onObjectHighlight(undefined)
        return
      }
      if (!obj.name) return
      if (obj.name.toLowerCase().includes('helper')) return // exclude all kind of helpers : camera, controls etc...
      if (obj.type === 'Line') return
      if (obj.name === 'TransformControls') return
      this.onObjectHighlight(obj)
    })

    this._settings = {}
    this._exposure = 1
    this._skybox = 'darkgray'
    this._envmapIntensity = 0.4

    this._world = {}
    this._mappings = []
    this._bookmarks = []
    // this._bookmarks2 = []
    this._lastActiveBookmark = null
    this._bookmarkActive = false
    this._showHelpers = false
    this._envMap = null
    this.busy = false

    this._hasChanges = false
    this._lastChange = null
    this._hasSequenceChanges = false
    this.changedTextures = []

    this.modules = new Modules()
  }

  setupLoadingManager() {
    const manager = new THREE.LoadingManager()

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      let pc = Math.round((itemsLoaded / itemsTotal) * 100, 2)
      let message = itemsLoaded + ' of ' + itemsTotal + ' loaded'

      pino.info({
        loading: true,
        message: message,
        pc: pc
      })
    }

    manager.onError = function (url) {
      pino.info('Error loading resource')
    }

    return manager
  }

  loadSettings(settings) {
    this.settings = new Settings(settings)
  }

  getCleanSceneForExport() {
    // Loop through all materials and properly name their maps
    try {
      this.enforceNamingOnMaterialMaps()

      let cleanScene = this.main.scene.clone()

      cleanScene =
        this.meshHelper.revertAllMeshesToOriginalTexturesAndUvs(cleanScene)

      return cleanScene
    } catch (err) {
      console.warn('Error cleaning scene', err)
      return false
    }
  }

  revertToBaseScene() {
    this.main.scene = this.meshHelper.revertAllMeshesToOriginalTexturesAndUvs(
      this.main.scene
    )
    this.setDirty()
  }

  /**
   * Enforce names for material maps
   *
   * This function loops through all materials in the scene tree
   * and then all 'map' attributes within and assigns regular naming
   * onto the textures.
   * This is later used for our extracted textures so names are consistent
   * and uniform, and bypass the index based accessor naming of the default
   * gltf export naming
   */
  enforceNamingOnMaterialMaps() {
    let materials = this.sceneMaterialsTree.children

    materials.forEach((material) => {
      let baseName = material.name
      Object.keys(material).forEach((key) => {
        if ((key.endsWith('Map') || key === 'map') && material[key] !== null) {
          let name = baseName + '_' + key.replace('Map', '')

          material[key].name = name
        }
      })
    })
  }

  setHasChanges() {
    this.emitter.emit('refresh')
    this.hasChanges = true
    this.lastChange = Date.now()
  }

  clearChanges() {
    this.hasChanges = false
    this.lastChange = null
  }

  get hasChanges() {
    return this._hasChanges
  }

  set hasChanges(val) {
    this._hasChanges = val
  }

  get lastChange() {
    return this._lastChange
  }

  set lastChange(val) {
    this._lastChange = val
  }

  get pickedObject() {
    return this._pickedObject
  }

  set pickedObject(obj) {
    this._pickedObject = obj
  }

  clearChangedTextures() {
    this.changedTextures = []
  }

  setHasTextureChanged(node, attribute) {
    let name = node.name

    if (name === '') {
      // Check the parent for a name
      // Materials aren't always properly named
      name = node.parent.name
    }

    this.changedTextures.push(name + '_' + attribute)
  }

  updateNode(updateFunc) {
    updateFunc()
    this.updateOutlineOnMeshTransform()
    this.setHasChanges()
    this.setDirty()
  }

  init(settings) {
    this.initSkybox()

    if (settings === undefined || settings === null) return

    // we only care about the world settings right now
    if (settings.world !== undefined) this._world = settings.world

    if (settings.mappings !== undefined) this._mappings = settings.mappings

    this.initBookmarks(settings.bookmarks)

    Object.keys(this._world).forEach(
      function (key, index) {
        this.setSetting(key, this._world[key])
      }.bind(this)
    )

    this.setDirty()
  }

  initBookmarks(bookmarks) {
    if (bookmarks === undefined) {
      bookmarks = []
    }

    let hasDefault = false
    bookmarks.forEach((bookmark) => {
      // this._bookmarks2 = new Bookmark(bookmark)

      if (bookmark.id === undefined) bookmark.id = generateUuid()
      if (bookmark.is_default === true) hasDefault = true
      bookmark.type = 'PrevizBookmark'

      this._bookmarks.push(bookmark)
    })

    if (hasDefault !== true && this._bookmarks.length > 0) {
      this._bookmarks[0].is_default = true
    }
  }

  updateBookmark(bookmark) {
    bookmark.data = this.getCurrentViewForBookmark()
  }

  activateDefaultBookmark() {
    if (this._bookmarks.length < 1) {
      this.controls.focusOnAndRotate(this.main.scene.children[0]) // exclude global_lights which is the second and last item in this.main.scene.children
      this.setDirty()
    } else {
      let defaultBookmark = this._bookmarks[0]

      this._bookmarks.forEach((row) => {
        if (row.is_default === true) defaultBookmark = row
      })

      this.activateBookmark(defaultBookmark)
    }
  }

  deleteBookmark(bookmark) {
    let index = this._bookmarks.findIndex((row) => row.id === bookmark.id)
    if (index > -1) this._bookmarks.splice(index, 1)
  }

  getCurrentViewForBookmark() {
    let position = this.controls.cameraControls.getPosition()
    let target = this.controls.cameraControls.getTarget()

    let data = {
      position: {
        x: position.x,
        y: position.y,
        z: position.z
      },
      target: {
        x: target.x,
        y: target.y,
        z: target.z
      },
      fov: this.camera.threeCamera.fov,
      near: this.camera.threeCamera.near,
      far: this.camera.threeCamera.far
    }

    return data
  }

  newBookmark(makeDefault) {
    let name = 'Bookmark'
    if (makeDefault === undefined || makeDefault !== true) {
      makeDefault = false
      if (this._bookmarks.length > 0) {
        name = 'Bookmark ' + (this._bookmarks.length + 1)
      }
    }

    let data = this.getCurrentViewForBookmark()

    let newBookmark = {
      id: generateUuid(),
      name: name,
      is_default: false,
      data: data,
      type: 'PrevizBookmark'
    }

    this._bookmarks.push(newBookmark)

    if (makeDefault === true) this.makeBookmarkDefault(newBookmark)

    return newBookmark
  }

  makeBookmarkDefault(bookmark) {
    this._bookmarks.forEach((bookmark) => {
      bookmark.is_default = false
    })

    bookmark.is_default = true
  }

  activateBookmark(bookmark) {
    this.controls.activate(bookmark)
    this.camera.activate(bookmark)

    this.activeBookmark = bookmark
    this.setDirty()
  }

  tick(delta) {
    if (this.animation) {
      this.animation.update(delta)
    }
  }

  setDirtyAnimation() {
    this.main.dirtyAnimation = true
  }

  unsetDirtyAnimation() {
    this.main.dirtyAnimation = false
  }

  setDirtyPlayer() {
    this.main.dirtyPlayer = true
  }

  unsetDirtyPlayer() {
    this.main.dirtyPlayer = false
  }

  setDirty() {
    this.main.requestRenderIfNotRequested()
  }

  addPlaybackResources(resources) {
    this.texture.addResources(resources)
  }

  remapResourceIdToNewValue(originalValue, updatedValue) {
    // Update the scene markers..

    this.texture.remapResourceIdToNewValue(originalValue, updatedValue)
  }

  clearScene() {
    this.main.scene.remove.apply(this.main.scene, this.main.scene.children)
    this.main.scene._lastChange = Date.now()
    // Also clear any animations
    if (this.model !== null) {
      this.model.clear()
      // this.animation.clear()
    }
  }

  loadSceneItem(item) {
    let src = null
    let settings = null

    if (item !== null) {
      if (item.url !== undefined && item.url !== '') {
        src = item.url
      }

      if (item.src !== undefined && item.src !== '') {
        src = item.src
      }

      if (item.settings !== undefined && item.settings !== '') {
        settings = item.settings
      }
    }

    this.model.load(
      src,
      () => {
        this.lightsUp()
        this.reInitTree()
      },
      settings
    )
  }

  loadFallbackScene() {
    this.initSkybox()
    if (this.model === null) {
      this.model = new Model(
        this.main.scene,
        this.manager,
        this.main.loadingBar,
        [],
        this.main
      )
      this.model.loadFallbackContent()
    }
  }

  loadWorld(world, resources) {
    this.initSkybox()

    let loadables = []

    world.objects.forEach((object) => {
      let resource = resources.find((res) => res.id === object.resource_id)
      if (resource !== undefined) {
        object.src = resource.url
      }

      loadables.push(object)
    })

    this.clearScene()

    if (this.model === null) {
      this.model = new Model(
        this.main.scene,
        this.manager,
        this.main.loadingBar,
        resources,
        this.main
      )
    }

    loadables.forEach((loadable) => {
      this.loadSceneItem(loadable)
    })
  }

  loadScene(scene, resources) {
    this.initSkybox()

    let loadables = []

    if (typeof scene === 'object') {
      loadables = [scene]
    }

    // loadables.push({ src: '/static/models/Astronaut.glb', settings: { position: { x: -1, y: 6, z: 4 }, rotation: { x: 10, y: 0, z: 100 }, scale: { x: 2, y: 2, z: 2 }} })
    // loadables.push({ src: '/static/models/Astronaut.glb', settings: { position: { x: 3, y: 3, z: 0 }, rotation: { x: 0.9, y: 0.4, z: 0.1 }, scale: { x: 0.7, y: 0.7, z: 0.7 }} })
    // loadables.push({ src: '/static/models/Astronaut.glb', settings: { position: { x: -5, y: 3, z: 0 }, rotation: { x: 0.4, y: 1.2, z: 2 }, scale: { x: 1.4, y: 1.4, z: 1.4 }} })
    // loadables.push({ src: '/static/models/Astronaut.glb', settings: { position: { x: -1, y: 6, z: 4 }, rotation: { x: 10, y: 0, z: 100 }, scale: { x: 2, y: 2, z: 2 }} })
    // loadables.push({ src: '/static/models/tests/warehouse/previz_demo_warehouse_280.glb' })

    // Clear out the scene if it's not already empty
    // This is to handle the case a scene is replaced in the editors
    this.clearScene()
    if (this.model === null) {
      this.model = new Model(
        this.main.scene,
        this.manager,
        this.main.loadingBar,
        resources,
        this.main
      )
    }

    loadables.forEach((loadable) => {
      this.loadSceneItem(loadable)
    })
  }

  lightsUp() {
    // Initialize passed settings
    // this.main.light.addDirectionalLight()
    this.init(this.settings.settings)

    pino.info('Lights up')
    this.main.setReady()
  }

  export() {
    let ret = {}

    ret.world = this._world
    ret.bookmarks = this._bookmarks
    ret.mappings = this._mappings

    return ret
  }

  deleteObjectById(objectId) {
    let node = this.main.scene.getObjectById(objectId)
    if (node !== undefined) {
      let parent = node.parent
      parent.remove(node)
      this.setDirty()
    }
  }

  loadImageAndSetToObject(img, node, attribute) {
    if (node !== undefined) {
      const loader = new THREE.TextureLoader()
      loader.load(
        img,
        (texture) => {
          texture.flipY = false
          texture.encoding = THREE.sRGBEncoding

          // texture.wrapS = THREE.RepeatWrapping // ClampToEdgeWrapping is the default
          // texture.wrapT = THREE.RepeatWrapping
          if (node.isMaterial) {
            if (attribute === 'aoMap') {
              // AO Maps require a second UV set
              this.enforceUV2ForMeshesUsingMaterial(node.name)
            }

            node[attribute] = texture
            node.needsUpdate = true

            this.setHasTextureChanged(node, attribute)
          }
          this.setDirty()
        },
        null // Helpers.logProgress(),
        // xhr => reject(new Error(xhr + 'An error occurred loading while loading ' + this.url))
      )
    }
  }

  enforceUV2ForMeshesUsingMaterial(materialName) {
    let meshes = this.filterMeshesUsingMaterial(materialName)
    meshes.forEach((mesh) => {
      if (!mesh.geometry.attributes.hasOwnProperty('uv2')) {
        pino.info('Automatically added uv2 to mesh "' + mesh.name + '"')
        mesh.geometry.setAttribute(
          'uv2',
          new THREE.BufferAttribute(mesh.geometry.attributes.uv.array, 2)
        )
      }
    })
  }

  filterMeshesUsingMaterial(materialName) {
    let meshes = []
    this.main.scene.traverse((child) => {
      if (child.material) {
        if (Array.isArray(child.material)) {
          child.material.forEach((mat) => {
            if (mat.name === materialName) {
              meshes.push(child)
            }
          })
        } else {
          if (child.material.name === materialName) {
            meshes.push(child)
          }
        }
      }
    })

    return meshes
  }

  initSkybox() {
    let preset = this._skybox

    let tex = new THREE.CubeTextureLoader()
      .setPath('/static/textures/cubemaps/' + preset + '/')
      .load(
        ['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png'],
        () => {
          this._envMap = tex
          this.updateSkyboxEverywhere()
        }
      )
  }

  updateSkyboxEverywhere() {
    this.main.scene.background = this._envMap

    // Also on all materials that can handle it ..
    let mats = this.extractMaterialsFromSceneTree()

    mats.forEach((mat) => {
      if (mat.envMap !== undefined && this._envMap !== mat.envMap) {
        mat.envMap = this._envMap
        mat.envMapIntensity = this._envmapIntensity
        mat.needsUpdate = true
      }
    })

    this.setDirty()
  }

  get errorMessages() {
    return this.logger.errorMessages
  }

  get messages() {
    return this.logger.history
  }

  get recentMessages() {
    return this.logger.recentHistory
  }

  get isLoading() {
    return this.logger.isLoading
  }

  get loadingPc() {
    return this.logger.loadingPc
  }

  get settings() {
    return this._settings
  }

  set settings(value) {
    this._settings = value
  }

  get showHelpers() {
    return this._showHelpers
  }

  set showHelpers(value) {
    this._showHelpers = Boolean(value)
    // @todo
  }

  // Start Bookmarks
  get bookmarks() {
    return this._bookmarks
  }

  get lastActiveBookmark() {
    return this._lastActiveBookmark
  }

  set lastActiveBookmark(val) {
    this._lastActiveBookmark = val
  }

  get activeBookmark() {
    if (this.bookmarkActive) return this.lastActiveBookmark
    return null
  }

  set activeBookmark(val) {
    this.lastActiveBookmark = val
    this.bookmarkActive = true
  }

  get bookmarkActive() {
    return this._bookmarkActive
  }

  set bookmarkActive(value) {
    this._bookmarkActive = value
  }

  checkBookmarkActiveState() {
    if (this.activeBookmark === null) return

    let current = this.getCurrentViewForBookmark()
    let existing = this.activeBookmark

    // Step through and return on the first change
    if (!this.checkPositionDataMatch(current, existing.data)) {
      this.bookmarkActive = false
    }
  }

  checkRoughMatch(value1, value2) {
    if (value1 === null || value2 === null) return false

    let a = value1.toFixed(3)
    let b = value2.toFixed(3)

    return a === b
  }

  /*
   * This check is as dumb as can be
   * I make no applogies
   */
  checkPositionDataMatch(pos1, pos2) {
    if (!this.checkRoughMatch(pos1.position.x, pos2.position.x)) return false
    if (!this.checkRoughMatch(pos1.position.y, pos2.position.y)) return false
    if (!this.checkRoughMatch(pos1.position.z, pos2.position.z)) return false

    // Target might not be defined to legacy bookmarks
    if (pos2.target.x !== undefined) {
      if (!this.checkRoughMatch(pos1.target.x, pos2.target.x)) return false
      if (!this.checkRoughMatch(pos1.target.y, pos2.target.y)) return false
      if (!this.checkRoughMatch(pos1.target.z, pos2.target.z)) return false
    }

    if (!this.checkRoughMatch(pos1.fov, pos2.fov)) return false
    if (!this.checkRoughMatch(pos1.near, pos2.near)) return false
    if (!this.checkRoughMatch(pos1.far, pos2.far)) return false

    return true
  }

  get indexOfActiveBookmark() {
    if (this._lastActiveBookmark !== null) {
      return this._bookmarks.findIndex(
        (row) => row.id === this._lastActiveBookmark.id
      )
    }
    return null
  }

  activatePrevBookmark() {
    let index = this.indexOfActiveBookmark
    if (index === null) index = 0
    else {
      // Jump to the prev
      index = index - 1
    }

    if (index < 0) {
      index = this._bookmarks.length - 1
    }

    if (this._bookmarks[index]) {
      this.activateBookmark(this._bookmarks[index])
    }
  }

  activateNextBookmark() {
    let index = this.indexOfActiveBookmark
    if (index === null) index = 0
    else {
      // Jump to the next
      index = index + 1
    }

    if (index >= this._bookmarks.length) {
      index = 0
    }

    if (this._bookmarks[index]) {
      this.activateBookmark(this._bookmarks[index])
    }
  }

  // End Bookmarks

  // get pickMode () {
  //   return this._pickMode
  // }

  // set pickMode (value) {
  //   this._pickMode = value
  // }

  get renderMode() {
    if (this.renderer.postProcessingEnabled) return ''
    return this.meshHelper.renderMode
  }

  get renderQuality() {
    return this.renderer.quality
  }

  get renderPostProcessingEnabled() {
    return this.renderer.postProcessingEnabled
  }

  get renderPostProcessingAvailable() {
    return this.renderer.postProcessingAvailable
  }

  get animationsActive() {
    return this.animation.activeClip
  }

  get animationsAvailable() {
    if (this.model !== null) return this.model.animations.length > 0
    return false
  }

  get animations() {
    if (this.model !== null) return this.model.animations
    return []
  }

  get animationPlaybackMode() {
    return 'loop'
  }

  get animationIsPlaying() {
    return this.animation.playing
  }

  get animationTime() {
    return this.animation.time()
  }

  getAnimationObjectByName(name) {
    return this.animation.getByName(name)
  }

  togglePlayAnimation(name) {
    this.animation.toggleOrReplace(name)

    if (this.animation.playing !== true) {
      pino.log('[gui] animation stopping playback render')
      this.unsetDirtyAnimation()
    } else {
      pino.log('[gui] animation starting playback render')
      this.setDirtyAnimation()
    }
  }

  scrubAnimation(name, time) {
    this.animation.scrubAnimation(name, time)
    this.setDirty()
  }

  readyAnimations() {
    if (this.animationsAvailable) {
      pino.log('[gui] readying animations')
      this.animation.init(this.main.scene, this.model.animations)
    }
  }

  renderPostProcessingEnable() {
    this.renderer.enablePostProcessing = true
    this.setDirty()
  }

  renderPostProcessingDisable() {
    this.renderer.enablePostProcessing = false
    this.setDirty()
  }

  changeRenderQuality(quality) {
    this.renderer.changeQuality(quality)
    this.setDirty()
  }

  changeRenderMode(mode) {
    if (mode !== 'default') {
      this.normaliseLights()
    } else {
      this.unnormaliseLights()
    }

    this.meshHelper.enterRenderMode(mode)
    this.setDirty()
  }

  normaliseLights() {
    this.setExposure(1)
  }

  unnormaliseLights() {
    this.setExposure(this._exposure)
  }

  cycleRenderQuality() {
    this.renderer.cycleQuality()
    this.setDirty()
  }

  cycleRenderMode() {
    this.meshHelper.cycleRenderMode()
    this.setDirty()
  }

  toggleGroundPlane() {
    this.model.loadFallback()
  }

  toggleHelpers() {
    this.showHelpers = !this.showHelpers
    var cameraHelper = new THREE.CameraHelper(this.camera.threeCamera)
    this.main.scene.add(cameraHelper)
  }

  getSetting(name) {
    switch (name) {
      case 'exposure':
        // return (Math.log(this.renderer.threeRenderer.toneMappingExposure
        return this._exposure
      // return this.renderer.threeRenderer.toneMappingExposure

      case 'background.color':
        return '#' + this.renderer.threeRenderer.getClearColor().getHexString()

      case 'background.skybox':
        return this._skybox
      case 'background.envmap.intensity':
        return this._envmapIntensity

      // LIGHTS

      // Ambient Light
      case 'light.ambient.visible':
        return this.light.ambientLight.visible
      case 'light.ambient.intensity':
        return this.light.ambientLight.intensity
      case 'light.ambient.color':
        return '#' + this.light.ambientLight.color.getHexString()
      // Ambient Light end

      // Hemi Light
      case 'light.hemi.visible':
        return this.light.hemiLight.visible
      case 'light.hemi.intensity':
        return this.light.hemiLight.intensity
      case 'light.hemi.color':
        return '#' + this.light.hemiLight.color.getHexString()
      case 'light.hemi.groundcolor':
        return '#' + this.light.hemiLight.groundColor.getHexString()
      case 'light.hemi.position.x':
        return this.light.hemiLight.position.x
      case 'light.hemi.position.y':
        return this.light.hemiLight.position.y
      case 'light.hemi.position.z':
        return this.light.hemiLight.position.z

      // Hemi Light End

      // WORLD

      case 'world.scale':
        // this '.x' is intentional. scale returns a vector3, but we only allow 1:1:1 scaling
        // so we just return the x value, and lock everything together
        return this.main.scene.scale.x
      case 'world.position.x':
        return this.main.scene.position.x
      case 'world.position.y':
        return this.main.scene.position.y
      case 'world.position.z':
        return this.main.scene.position.z
      case 'world.rotation.x':
        return this.main.scene.rotation.x
      case 'world.rotation.y':
        return this.main.scene.rotation.y
      case 'world.rotation.z':
        return this.main.scene.rotation.z

      // Camera
      case 'camera.fov':
        return this.camera.threeCamera.fov
      case 'camera.near':
        return this.camera.threeCamera.near
      case 'camera.far':
        return this.camera.threeCamera.far

      // ENV
      case 'env.ground.enabled':
        return this.model.groundPlaneVisibility

      // Effects
      case 'effects.bloom.enabled':
        return this.main.renderer.effects.bloom.enabled
      case 'effects.bloom.threshold':
        return this.main.renderer.effects.bloom.threshold
      case 'effects.bloom.smoothing':
        return this.main.renderer.effects.bloom.smoothing
      case 'effects.bloom.intensity':
        return this.main.renderer.effects.bloom.intensity

      case 'effects.SSR.enabled':
        return this.main.renderer.effects.SSR.enabled
    }

    return null
  }

  setSetting(name, value) {
    this._world[name] = value

    switch (name) {
      case 'exposure':
        value = Number(value)
        if (this._exposure !== value) {
          this._exposure = value
          this.setExposure(value)
        }
        break
      case 'background.color':
        this.renderer.threeRenderer.setClearColor(new THREE.Color(value))
        break

      case 'background.skybox':
        this._skybox = value
        this.initSkybox()
        break
      case 'background.envmap.intensity':
        this._envmapIntensity = value
        this.initSkybox()
        break

      case 'light.ambient.visible':
        this.light.update(name, value)
        break
      case 'light.ambient.intensity':
        this.light.update(name, value)
        break
      case 'light.ambient.color':
        this.light.update(name, value)
        break
      case 'light.hemi.visible':
        this.light.update(name, value)
        break
      case 'light.hemi.intensity':
        this.light.update(name, value)
        break
      case 'light.hemi.color':
        this.light.update(name, value)
        break
      case 'light.hemi.groundcolor':
        this.light.update(name, value)
        break
      case 'light.hemi.position.x':
        this.light.update(name, value)
        break
      case 'light.hemi.position.y':
        this.light.update(name, value)
        break
      case 'light.hemi.position.z':
        this.light.update(name, value)
        break

      case 'world.scale':
        value = Number(value)
        this.main.scene.scale.x = value
        this.main.scene.scale.y = value
        this.main.scene.scale.z = value
        break
      case 'world.position.x':
        value = Number(value)
        this.main.scene.position.x = value
        break
      case 'world.position.y':
        value = Number(value)
        this.main.scene.position.y = value
        break
      case 'world.position.z':
        value = Number(value)
        this.main.scene.position.z = value
        break
      case 'world.rotation.x':
        value = Number(value)
        this.main.scene.rotation.x = value
        break
      case 'world.rotation.y':
        value = Number(value)
        this.main.scene.rotation.y = value
        break
      case 'world.rotation.z':
        value = Number(value)
        this.main.scene.rotation.z = value
        break

      // Camera
      case 'camera.fov':
        value = Number(value)
        this.camera.threeCamera.fov = value
        this.camera.threeCamera.updateProjectionMatrix()
        break
      case 'camera.near':
        value = Number(value)
        this.camera.threeCamera.near = value
        this.camera.threeCamera.updateProjectionMatrix()
        break
      case 'camera.far':
        value = Number(value)
        this.camera.threeCamera.far = value
        this.camera.threeCamera.updateProjectionMatrix()
        break

      // Env
      case 'env.ground.enabled':
        value = Boolean(value)
        this.model.setGroundPlaneVisibility(value)
        break

      case 'effects.bloom.enabled':
        value = Boolean(value)
        this.main.renderer.effects.bloom.enabled = value
        this.main.renderer.updatePostProcessingSettings()
        break
      case 'effects.bloom.threshold':
        this.main.renderer.effects.bloom.threshold = value
        this.main.renderer.updatePostProcessingSettings()
        break
      case 'effects.bloom.smoothing':
        this.main.renderer.effects.bloom.smoothing = value
        this.main.renderer.updatePostProcessingSettings()
        break
      case 'effects.bloom.intensity':
        this.main.renderer.effects.bloom.intensity = value
        this.main.renderer.updatePostProcessingSettings()
        break

      case 'effects.SSR.enabled':
        value = Boolean(value)
        this.main.renderer.effects.SSR.enabled = value
        this.main.renderer.updatePostProcessingSettings()
        break
    }

    this.setDirty()
  }

  setExposure(value) {
    this.renderer.threeRenderer.toneMappingExposure = Math.pow(value, 5.0)
  }

  get sceneModulesTree() {
    return this.modules.toNode()
  }

  get sceneNodeTree() {
    return this.main.scene
  }

  filteredSceneTree(filter) {
    let filtered = []
    filter = filter.toLowerCase()

    this.sceneNodeTree.traverse((child) => {
      if (child.name.toLowerCase().includes(filter)) filtered.push(child)
    })

    return {
      name: 'Nodes',
      type: 'SceneBaseGroup',
      children: filtered
    }
  }

  get sceneMaterialsTree() {
    return {
      name: 'Materials',
      type: 'MaterialGroup',
      children: this.extractMaterialsFromSceneTree()
    }
  }

  filteredMaterialsTree(filter) {
    filter = filter.toLowerCase()
    let children = this.sceneMaterialsTree.children.filter(function (material) {
      return material.name.toLowerCase().includes(filter)
    })

    return {
      name: 'Materials',
      type: 'MaterialGroup',
      children: children
    }
  }

  extractMaterialsFromSceneTree() {
    let materials = []
    let seenMats = []

    this.main.scene.traverse((child) => {
      if (child._originalMaterial) {
        if (!seenMats.includes(child._originalMaterial.id)) {
          materials.push(child._originalMaterial)
          seenMats.push(child._originalMaterial.id)
        }
      } else {
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach((mat) => {
              if (!seenMats.includes(mat.id)) {
                materials.push(mat)
                seenMats.push(mat.id)
              }
            })
          } else {
            if (!seenMats.includes(child.material.id)) {
              materials.push(child.material)
              seenMats.push(child.material.id)
            }
          }
        }
      }
    })

    return materials
  }

  get sceneAnimationsTree() {
    let animations = []

    this.animations.forEach((animation) => {
      animations.push({
        type: 'Animation',
        name: animation.name,
        uuid: null
      })
    })

    return {
      name: 'Animations',
      type: 'AnimationGroup',
      children: animations
    }
  }

  getAnimationByName(name) {
    let animation = this.sceneAnimationsTree.children.find(
      (row) => row.name === name
    )
    return animation
  }

  filteredAnimationsTree(filter) {
    filter = filter.toLowerCase()
    let children = this.sceneAnimationsTree.children.filter(function (
      animation
    ) {
      return animation.name.toLowerCase().includes(filter)
    })

    return {
      name: 'Animations',
      type: 'AnimationGroup',
      children: children
    }
  }

  get sceneTexturesTree() {
    return {
      name: 'Textures',
      type: 'TextureGroup',
      children: this.extractTexturesFromSceneTree()
    }
  }

  extractTexturesFromSceneTree() {
    let textures = []
    let seenTextures = []

    this.sceneMaterialsTree.children.forEach((mat) => {
      Object.keys(mat).forEach((key) => {
        if ((key.endsWith('Map') || key === 'map') && mat[key] !== null) {
          if (!seenTextures.includes(mat[key].id)) {
            // let name = baseName + '_' + key.replace('Map', '')
            mat[key].type = THREE.UnsignedByteType
            mat[key].parent_uuid = mat.uuid
            mat[key].map_type = key
            textures.push(mat[key])

            seenTextures.push(mat[key].id)
          }
        }
      })
    })

    return textures
  }

  filteredTexturesTree(filter) {
    filter = filter.toLowerCase()
    let children = this.sceneTexturesTree.children.filter(function (material) {
      return material.name.toLowerCase().includes(filter)
    })

    return {
      name: 'Textures',
      type: 'TextureGroup',
      children: children
    }
  }

  getObjectById(id) {
    let node = this.main.scene.getObjectById(id)
    if (node !== undefined) return node

    node = this.main.scene.getObjectByName(id)
    if (node !== undefined) return node

    this.main.scene.traverse((child) => {
      if (child.uuid === id) {
        node = child
        return node
      }
    })
    if (node !== undefined) return node

    node = this.getMaterialByName(id)
    if (node !== null) return node

    node = this.getTextureByName(id)
    if (node !== null) return node

    node = this.getAnimationByName(id)
    if (node !== null) return node

    console.warn(
      '[GUI] Failed to find a node with matching ID or Name in SceneTree, MaterialsTree, TexturesTree or AnimationsTree : ' +
        id
    )
    return null
  }

  searchTreeForNameOrId(tree, idOrName) {
    let match = null

    tree.forEach((node) => {
      if (node.name === idOrName || node.uuid === idOrName) {
        match = node
      }
    })

    return match
  }

  getMaterialByName(name) {
    return this.searchTreeForNameOrId(this.sceneMaterialsTree.children, name)
  }

  getTextureByName(name) {
    return this.searchTreeForNameOrId(this.sceneTexturesTree.children, name)
  }

  importIntoScene(file, clear, callback, settings) {
    if (clear === true) {
      this.clearScene()
    }

    this.model.load(
      file,
      () => {
        if (clear === true) {
          // Re-init any base lights etc.
          this.lightsUp()
        }

        this.setHasChanges()
        this.setDirty()
        this.reInitTree()

        if (callback !== undefined) callback()
      },
      settings
    )
  }

  reInitTree() {
    this.main.scene._lastChange = Date.now()
    pino.log('[gui] Forcing a re-evaluation on scene tree')

    this.readyAnimations()
  }

  resetCamera() {
    this.controls.reset()
    this.setDirty()
  }

  focusObject(node) {
    if (node === null) return
    this.controls.focusOn(node)
    this.setDirty()
  }

  onObjectHighlight(obj) {
    this.removeSelectionOutline()
    if (obj) {
      this.highlightObject(obj)
    }
  }

  onObjectSelected(obj) {
    // this.onObjectHighlight(obj)
    // this.attachTransformControlsToSelected()
  }

  attachTransformControlsToSelected() {
    this.controls.transformControls.attach(this.selectedObj)
    this.main.scene.add(this.controls.transformControls)
  }

  initSelectionOutline() {
    this._selectionOutline = new THREE.BoxHelper(this.main.scene, 0xffff00)
    this._selectionOutline.material.linewidth = 5
    this._selectionOutline._ignoreExport = true
    this._selectionOutline._ignoreSceneGraph = true
    this._selectionOutline.name = 'Box Helper'
    this.main.scene.add(this._selectionOutline)
  }

  removeSelectionOutline() {
    this.main.fatLines = []
    let match = this.main.scene.getObjectByName('Highlighted Line')
    if (match) {
      match.parent.remove(match)
      this.setDirty()
    }
  }

  highlightObjectByName(name) {
    this.removeSelectionOutline()
    if (!name) return
    let obj = this.main.scene.getObjectByName(name)
    if (obj) {
      this.highlightObject(obj)
    }
  }

  highlightObject(obj) {
    if (!obj) return
    if (obj.isMaterial || obj.isTexture) return
    obj.matrixWorldNeedsUpdate = false
    obj.updateMatrixWorld(true)

    let lineGeom = new THREE.EdgesGeometry(obj.geometry, 40)
    const thickLineGeom = new LineSegmentsGeometry().fromEdgesGeometry(lineGeom)
    const fatLine = new LineMaterial({
      color: 0x890dea,
      linewidth: 4,
      depthTest: false
    })

    let wireframe = new LineSegments2(thickLineGeom, fatLine)
    wireframe.name = 'Highlighted Line'
    wireframe.computeLineDistances()
    wireframe.renderOrder = 9
    wireframe.scale.set(1, 1, 1)
    wireframe.position.copy(obj.position)
    wireframe.scale.copy(obj.scale)
    wireframe.rotation.copy(obj.rotation)
    obj.parent.add(wireframe)
    this.main.fatLines.push(wireframe)

    this.setDirty()
  }

  updateOutlineOnMeshTransform() {
    this._selectionOutline.update()
  }

  colorFloatToColor(floatArray) {
    if (floatArray.length !== 3) return new THREE.Color()
    return new THREE.Color(floatArray[0], floatArray[1], floatArray[2])
  }
}

function generateUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, (c) =>
      (
        c ^
        (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
    .toUpperCase()
}
