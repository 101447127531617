// import * as THREE from 'three'
import Player from '../sequence/player'
import Timeline from '../sequence/timeline'
import Resources from '../sequence/resources'

// Main sequence class
export default class Sequence {
  constructor (main, sequence, resources) {
    // Properties
    this.main = main
    this.sequence = sequence

    if (sequence.id !== null) {
      this.id = sequence.id
    } else {
      this.id = generateUuid()
    }

    this.player = new Player()
    this.resources = new Resources()
    this.resources.load(resources)

    this.timeline = new Timeline(this.player, this.resources)
    this.timeline.load(sequence)
    this.timeline.compile()

    this.timeline.attachGui(main.gui)
  }
}

function generateUuid () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  ).toUpperCase()
}
