/* global pino */
// Provides simple static functions that are used multiple times in the app
export default class Helpers {
  static throttle (fn, threshhold, scope) {
    threshhold || (threshhold = 250)
    let last, deferTimer

    return function () {
      const context = scope || this

      const now = +new Date()

      const args = arguments

      if (last && now < last + threshhold) {
        clearTimeout(deferTimer)
        deferTimer = setTimeout(function () {
          last = now
          fn.apply(context, args)
        }, threshhold)
      } else {
        last = now
        fn.apply(context, args)
      }
    }
  }

  static setByDot (_obj, _path, _val) {
    return _path.split('.').reduce(function (prev, curr, _idx, _arr) {
      if (_idx === (_arr.length - 1) && prev) {
        prev[curr] = _val
      }

      return prev ? prev[curr] : null
    }, _obj)
  }

  static getByDot (_obj, _path) {
    return _path.split('.').reduce(function (prev, curr, _idx, _arr) {
      return prev ? prev[curr] : null
    }, _obj)
  }

  static logProgress (loadingbar) {
    return function (xhr) {
      if (xhr.lengthComputable) {
        const percentComplete = xhr.loaded / xhr.total * 100
        let pc = Math.round(percentComplete, 2)
        pino.info({ loading: true, pc: pc }) // Gross.
      }
    }
  }

  static logError () {
    return function (xhr) {
      console.error(xhr)
    }
  }

  static handleColorChange (color) {
    return (value) => {
      if (typeof value === 'string') {
        value = value.replace('#', '0x')
      }

      color.setHex(value)
    }
  }

  static update (mesh) {
    this.needsUpdate(mesh.material, mesh.geometry)
  }

  static needsUpdate (material, geometry) {
    return function () {
      material.shading = +material.shading // Ensure number
      material.vertexColors = +material.vertexColors // Ensure number
      material.side = +material.side // Ensure number
      material.needsUpdate = true
      geometry.verticesNeedUpdate = true
      geometry.normalsNeedUpdate = true
      geometry.colorsNeedUpdate = true
    }
  }

  static updateTexture (material, materialKey, textures) {
    return function (key) {
      material[materialKey] = textures[key]
      material.needsUpdate = true
    }
  }
}
