import * as THREE from 'three'
import CameraControls from 'camera-controls'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { EventEmitter } from 'events'

CameraControls.install({ THREE: THREE })

// Controls based on orbit controls
export default class Controls {
  constructor (camera, container, renderer) {
    this.emitter = new EventEmitter()
    this.cameraControls = new CameraControls(camera, container)
    this.transformControls = new TransformControls(camera, renderer.domElement)
    this.transformControls.name = "TransformControls" // used to exclude it from the object picking
    this.transformControls._ignoreSceneGraph = true
    this.manuallyMoved = false
    this.firstfocusedObject = null
    this.attachEventCallbacks()
  }

  attachEventCallbacks () {
    // camera controls
    this.cameraControls.addEventListener('update', (event) => {
      this.emitter.emit('controlupdate', event)
    })

    this.cameraControls.addEventListener('wake', (event) => {
      this.emitter.emit('controlwake', event)
    })

    this.cameraControls.addEventListener('sleep', (event) => {
      this.emitter.emit('controlsleep', event)
    })

    this.cameraControls.addEventListener('control', (event) => {
      this.emitter.emit('control', event)
    })

    this.cameraControls.addEventListener('controlstart', (event) => {
      this.manuallyMoved = true
      this.emitter.emit('controlstart', event)
    })

    this.cameraControls.addEventListener('controlend', (event) => {
      this.emitter.emit('controlend', event)
    })

    // transform controls
    this.transformControls.addEventListener('change', (event) => {
      this.emitter.emit('transformchange', event)
    })

    this.transformControls.addEventListener('dragging-changed', (event) => {
      this.emitter.emit('transformdraggingchanged', event)
    })

    this.transformControls.addEventListener('objectChange', event => {
      this.emitter.emit('transformobjectchange', event)
    })

    document.addEventListener('keydown', (event) => {
      switch (event.key) {
        case 's':
          this.transformControls.setMode('scale')
          break

        case 'r':
          this.transformControls.setMode('rotate')
          break

        case 't':
          this.transformControls.setMode('translate')
          break

        // default:
        // console.log('this key shortcut is not supported')
      }
    })

    // add key shortcut for transform controls
  }

  activate (bookmark) {
    // (Because we didn't always store the target info)
    let target = { x: 0, y: 0, z: 0 }
    let position = { x: 25, y: -25, z: 100 }

    if (bookmark.data.target.x !== undefined) target.x = bookmark.data.target.x
    if (bookmark.data.target.y !== undefined) target.y = bookmark.data.target.y
    if (bookmark.data.target.z !== undefined) target.z = bookmark.data.target.z
    if (bookmark.data.position.x !== undefined) position.x = bookmark.data.position.x
    if (bookmark.data.position.y !== undefined) position.y = bookmark.data.position.y
    if (bookmark.data.position.z !== undefined) position.z = bookmark.data.position.z

    this.cameraControls.setLookAt(
      position.x,
      position.y,
      position.z,
      target.x,
      target.y,
      target.z,
      true
    )

  }

  reset () {
    this.activate({ data: { target: { x: 0, y: 0, z: 0 }, position: { x: 25, y: -25, z: 100 } } });
    this.cameraControls.fitToBox(this.firstfocusedObject, true)
  }

  focusOn (object) {
    this.cameraControls.fitToBox(object, true)
  }

  async focusOnAndRotate (object) {
    this.firstfocusedObject = object;
    await this.cameraControls.fitToBox(object, true);
    if (!this.manuallyMoved) await this.cameraControls.rotateTo(Math.PI / 4, Math.PI / 2, true);
    if (!this.manuallyMoved) await this.cameraControls.rotateTo(-(Math.PI / 4), Math.PI / 2, true);
    if (!this.manuallyMoved) await this.cameraControls.fitToBox(object, true)
  }
}
