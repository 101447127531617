import * as THREE from 'three'
import { Texture } from 'three'

class FramebufferTexture extends Texture {
  constructor (width, height, format) {
    super({ width, height })

    this.isFramebufferTexture = true

    this.format = format

    this.magFilter = THREE.NearestFilter
    this.minFilter = THREE.NearestFilter

    this.generateMipmaps = false

    this.needsUpdate = true
  }
}

export { FramebufferTexture }
