/* global pino */
import * as THREE from 'three'
import Material from './material'

// Simple mesh helper that shows edges, wireframes, and face and vertex normals
export default class MeshHelper {
  constructor (scene, textures) {
    this.scene = scene
    this.textures = textures

    this.obj_name = 'meshhelper_wireline'

    this.wireframe = false
    this.mappings = false

    this._renderMode = 'default'

    this.maps = []
    this.highlighted = []
    this.highlightedGroup = null
    this.viewMode = false

    this._hasTempChanges = false
    this._targetStack = []
    this._originalMaterials = {}
  }

  setHasChanges () {
    this._hasTempChanges = true
  }

  get hasTempChanges () {
    return this._hasTempChanges
  }

  get availableMappings () {
    // let groups = []
    // let ret = [this.scene._sceneObjects.meshes, ...groups]

    return this.scene._sceneObjects.meshes
  }

  setMaterialOverride (type) {
    if (type === 'wire') {
      this.scene.overrideMaterial = new Material().wire
    } else {
      this.scene.overrideMaterial = new Material().normal
    }
  }

  unsetMaterialOverride () {
    this.scene.overrideMaterial = null
  }

  toggleMaterials () {
    if (this.scene.overrideMaterial === null) this.setMaterialOverride()
    else this.unsetMaterialOverride()
  }

  storeOriginalMaterialForMesh (mesh) {
    if (mesh._originalMaterial === undefined) {
      let materialId = mesh.material.id

      // Check if we have this material stored in our base
      if (this._originalMaterials[materialId] === undefined) {
        this._originalMaterials[materialId] = mesh.material
      }

      mesh._originalMaterial = this._originalMaterials[materialId]

      // Also store the id in user data
      mesh.userData.originalMaterialId = materialId

      let newClone = mesh.material.clone()
      mesh.material = newClone

      this.setHasChanges()
    }
  }

  getOriginalMaterialForMesh (mesh) {
    if (mesh._originalMaterial === undefined) {
      return mesh.material
    } else {
      return mesh._originalMaterial
    }
  }

  getCurrentMaterialForMesh (mesh) {
    return mesh.material
  }

  unsetTargetsFromTexture (targets, order) {
    let meshnames = targets // targets.split('|')

    meshnames.forEach(target => {
      let existingIndex = this._targetStack.findIndex(row => (row.target === target && row.order === order))
      if (existingIndex > -1) {
        this._targetStack.splice(existingIndex, 1)
      }
    })

    let orderedSet = []

    meshnames.forEach(name => {
      let parts = name.split(':')
      let meshname = parts[0]
      let uv = parts[1]

      let stack = this._targetStack.filter(row => {
        return row.meshname === meshname
      })

      // if the stack has a zero length, this texture being unset was the last applied texture
      // in this case we want to revert back to the original item texture
      if (stack.length > 0) {
        stack.sort(orderSort) // the all important ordering
        orderedSet[meshname] = stack
      } else {
        orderedSet[meshname] = [{
          meshname: meshname,
          revert: true
        }]
      }
    })

    Object.keys(orderedSet).forEach(key => {
      this.setModuleStackForMesh(key, orderedSet[key])
    })
  }

  setTargetsToTexture (targets, texture, order, settings) {
    let meshnames = targets

    meshnames.forEach(target => {
      let parts = target.split(':')
      let meshname = parts[0]
      let uv = parts[1]

      let v = {}
      v.target = target
      v.texture = texture
      v.settings = settings
      v.order = order
      v.meshname = meshname
      v.uv = uv

      let existingIndex = this._targetStack.findIndex((row) => {
        // if (row.target === target && row.order === order) return true // Exact match
        return (row.meshname === meshname && row.order === order)
      })
      if (existingIndex > -1) {
        this._targetStack[existingIndex] = v
      } else {
        this._targetStack.push(v)
      }
    })

    let orderedSet = {}
    meshnames.forEach(name => {
      let parts = name.split(':')
      let meshname = parts[0]

      let stack = this._targetStack.filter(row => {
        return row.meshname === meshname
      })

      stack.sort(orderSort) // the all important ordering
      orderedSet[meshname] = stack
    })

    Object.keys(orderedSet).forEach(key => {
      this.setModuleStackForMesh(key, orderedSet[key])
    })
  }

  setModuleStackForMesh (meshname, orderedSet) {
    // We take in a mesh target key ({mesh}) and a stack of _ordered_ modules
    // Then apply in order. Only the highest module per target channel will apply
    // So as soon as we see any duplicated channel target we skip
    let mesh = this.scene.getObjectByName(meshname)
    if (mesh === undefined) {
      console.warn('[mesh helper] Failed to find mesh with matching name during module stack set - ' + meshname)
      return
    }
    // Safety - store the mesh original by attaching a _originalMaterial property on the mesh
    this.storeOriginalMaterialForMesh(mesh) // This only runs if the _originalMaterial isn't already stored

    let material = this.getCurrentMaterialForMesh(mesh)// .clone()
    if (material.name && material.name.indexOf('_timeline_') < 0) {
      material = material.clone()
      material.name = material.name + '_timeline_' + material.id
    }
    material._ignoreExport = true

    let originalMaterial = this.getOriginalMaterialForMesh(mesh)

    let activeUv = null
    let channels = []

    orderedSet.forEach(set => {
      if (set.hasOwnProperty('revert')) {
        // material.dispose()
        material = originalMaterial
      } else {
        // We allow module targets to set a different active UV
        // But uvs are stored on geometries, not on the materials
        if (activeUv === null) {
          // The highest module gets priority setting the uv map
          if (set.uv !== undefined && set.uv !== null) activeUv = set.uv
        }

        let channel = 'emissiveandbase'
        if (set.settings.hasOwnProperty('textureChannel')) channel = set.settings.textureChannel

        if (!channels.includes(channel)) {
          let texture = set.texture
          if (texture === null) {
            texture = this.getChannelTextureFromMaterial(originalMaterial, channel)
          }

          if (set.settings.hasOwnProperty('textureAsLumaMatte') && set.settings.textureAsLumaMatte === true) {
            texture.repeat.y = 0.5
            texture.offset.y = 0.5
            if (set.settings.hasOwnProperty('flipY') && set.settings.flipY === true) texture.offset.y = 0 // 0 // 0.5

            texture.wrapT = THREE.MirroredRepeatWrapping
            let lumatex = texture.clone()
            material = this.setMaterialChannelToTexture(material, 'lumamatte', lumatex, set)
          }

          material = this.setMaterialChannelToTexture(material, channel, texture, set)
          channels.push(channel)
        } else {
          console.warn('[mesh helper] channel (' + channel + ') already set by higher priority module. Skipping')
        }
      }
    })

    this.setMeshToMat(mesh, material, { uv: activeUv })
  }

  getChannelTextureFromMaterial (material, channel) {
    switch (channel) {
      case 'alpha':
        return material.alphaMap
      case 'default':
        return material.map
      case 'emissive':
        return material.emissiveMap
      case 'emissiveandbase':
        return material.map
    }
    return null
  }

  setMaterialChannelToTexture (material, channel, texture, set) {
    let internalName = 'previz-timeline-set'

    if (material.type === 'ShaderMaterial') {
      material = new THREE.MeshStandardMaterial() // change to standardmaterial before applying texture otherwise it breaks cause they don't get updated the same way
    }

    // Apply any texture settings
    let settings = set.settings
    if (settings.hasOwnProperty('flipY')) {
      if (settings.flipY) {
        texture.flipY = true
      } else {
        texture.flipY = false
      }
    }

    switch (channel) {
      case 'lumamatte':
        texture.flipY = !texture.flipY
        material.transparent = true
        material.opacity = 1
        material.alphaMap = texture
        material.alphaMap.needsUpdate = true
        material.alphaMap.name = internalName
        break

      case 'alpha':
        material.transparent = true
        material.opacity = 1
        material.alphaMap = texture
        material.alphaMap.needsUpdate = true
        material.alphaMap.name = internalName
        break

      case 'emissive':
        material.emissiveMap = texture
        material.emissive.setHex(0xffffff) // Force the emissive color to white, emulating an LED output
        material.emissiveMap.needsUpdate = true
        material.emissiveMap.name = internalName
        break

      case 'default':
        material.map = texture
        material.map.needsUpdate = true
        material.map.name = internalName
        break

      case 'emissiveandbase':
        material.map = texture
        material.map.needsUpdate = true
        material.map.name = internalName
        material.emissiveMap = texture
        material.emissive.setHex(0xffffff) // Force the emissive color to white, emulating an LED output
        material.emissiveMap.needsUpdate = true
        material.emissiveMap.name = internalName
        break
    }

    material.needsUpdate = true
    return material
  }

  setMeshToTexture (meshname, texture, settings) {
    let mesh = this.scene.getObjectByName(meshname)
    if (mesh !== undefined) {
      if (texture === null) {
        // This is the unset call
        if (mesh._originalMaterial !== undefined) {
          this.setMeshToMat(mesh, this.getOriginalMaterialForMesh(mesh))
        }
      } else {
        this.storeOriginalMaterialForMesh(mesh)
        let material = this.createMaterialWithSettings(mesh, texture, settings)
        this.setMeshToMat(mesh, material, settings)
      }
    } else {
      console.warn('Failed to set mesh to texture. Mesh was not found by name : ' + meshname, this.scene)
    }
  }

  createMaterialWithSettings (mesh, texture, settings) {
    let channel = 'default'
    if (settings.hasOwnProperty('textureChannel')) {
      channel = settings['textureChannel']
    }

    switch (channel) {
      case 'alpha':
        channel = 'alpha'
        break
      default:
        channel = 'default'
        break
    }

    let material = this.getCurrentMaterialForMesh(mesh).clone()
    // let material = new THREE.MeshStandardMaterial({ map: texture })

    if (settings.hasOwnProperty('flipY')) {
      if (settings.flipY) {
        texture.flipY = true
      } else {
        texture.flipY = false
      }
    }

    material.needsUpdate = true
    material.map = texture // @todo
    material.map.needsUpdate = true

    // switch (channel) {
    //   case 'alphaMap':
    //     material.transparent = true
    //     material.alphaMap = texture
    //     material.alphaMap.needsUpdate = true
    //     break
    // //   default:
    // //     material.map = texture
    // //     break
    // }

    return material
  }

  setMeshToMat (mesh, material, settings) {
    // Start - handle UV swapping
    if (mesh.geometry && mesh.geometry.attributes) {
      if (!mesh.geometry.attributes.hasOwnProperty('_originalUV')) {
        mesh.geometry.attributes['_originalUV'] = mesh.geometry.attributes.uv
      }

      if (settings.uv !== undefined && mesh.geometry.attributes.hasOwnProperty(settings.uv)) {
        mesh.geometry.attributes.uv = mesh.geometry.attributes[settings.uv]
      } else {
        mesh.geometry.attributes.uv = mesh.geometry.attributes['_originalUV']
      }
    }
    mesh.material = material // whatever get sets here is applied
  }

  revertMeshToOriginalTexture (meshname) {
    let mesh = this.scene.getObjectByName(meshname)
    if (mesh !== undefined && mesh._originalMaterial !== undefined) {
      this.setMeshToMat(mesh, mesh._originalMaterial)
    }
  }

  revertAllMeshesToOriginalTexturesAndUvs (workingScene) {
    workingScene.traverse(child => {
      // Reset stored original materials
      if (child.isMesh) {
        if (child._originalMaterial !== undefined) {
          child.material = child._originalMaterial
          child.material.needsUpdate = true
          child.needsUpdate = true
        } else if (child.userData.originalMaterialId !== undefined) {
          let originalId = child.userData.originalMaterialId
          if (this._originalMaterials[originalId] !== undefined) {
            child.material = this._originalMaterials[originalId]
          }
        }
      }

      // Reset stored original uvs

      if (child.isMesh && child.geometry && child.geometry.attributes && child.geometry.attributes._originalUV !== undefined) {
        child.geometry.attributes.uv = child.geometry.attributes._originalUV
      }
    })

    return workingScene
  }

  get renderMode () {
    return this._renderMode
  }

  cycleRenderMode () {
    switch (this.renderMode) {
      case 'default':
        this.enterRenderMode('wireframe')
        break
      case 'wireframe':
        this.enterRenderMode('normal')
        break
      case 'normal':
        this.enterRenderMode('solid')
        break
      case 'solid':
      default:
        this.enterRenderMode('default')
        break
    }
    return this._renderMode
  }

  enterRenderMode (mode) {
    pino.info('Render mode changed to : ' + mode)

    if (mode === 'wireframe') {
      this.setMaterialOverride('wire')
      this.disableFlatShading()
      this.disableLighting()
      this._renderMode = 'wireframe'
    }

    if (mode === 'normal') {
      this.setMaterialOverride('normal')
      this.enableFlatShading()
      this.disableLighting()
      this._renderMode = 'normal'
    }

    if (mode === 'solid') {
      this.unsetMaterialOverride()
      this.enableFlatShading()
      this.disableLighting()
      this._renderMode = 'solid'
    }

    if (mode === 'default') {
      this.unsetMaterialOverride()
      this.disableFlatShading()
      this.enableLighting()
      this._renderMode = 'default'
    }
  }

  enableFlatShading () {
    this.scene.traverse(child => {
      if (child.isMesh) {
        child.material.flatShading = true
      }
    })
  }

  disableFlatShading () {
    this.scene.traverse(child => {
      if (child.isMesh) {
        child.material.flatShading = false
      }
    })
  }

  disableLighting () {
    this.scene.traverse(child => {
      if (child.isLight) {
        if (!(child.isAmbientLight || child.isHemisphereLight)) {
          if (child._intensity0 === undefined) child._intensity0 = child.intensity
          child.intensity = 0
        }
      }
    })
  }

  enableLighting () {
    this.scene.traverse(child => {
      if (child.isLight) {
        if (!(child.isAmbientLight || child.isHemisphereLight)) {
          if (child._intensity0 !== undefined) {
            child.intensity = child._intensity0
            delete child._intensity0
          } else {
            child.intensity = 1
          }
        }
      }
    })
  }

  setAllMeshesToWire () {
    const wiremat = new Material(0xffffff).wire
    this.scene.traverse(mesh => {
      if (mesh instanceof THREE.Mesh) {
        mesh.material = wiremat
      }
    })
  }

  setMeshToLambert (meshname) {
    const lambertmat = new Material(0xffffff).lambert
    lambertmat.map = this.textures.UV

    let mesh = this.scene.getObjectByName(meshname)
    if (mesh !== undefined) {
      mesh.material = lambertmat
    }
  }

  resetAll () {
    this.leaveHighlightMode()
  }
}

function orderSort (a, b) { return a.order - b.order }
