/* global File, Blob, atob */
// import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'
import {
  GLTFExporter
} from './gltfExporter'

// import {
//   WebIO
// } from '@gltf-transform/core'
// import {
//   dedup,
//   partition
// } from '@gltf-transform/lib'
// import {
//   KHRONOS_EXTENSIONS
// } from '@gltf-transform/extensions'

export default class Exporter {
  constructor () {
    this.scene = null
    this.timeline = null

    this.gui = null

    this.runThroughGltfTransform = false

    this.baseExportOptions = {
      binary: this.runThroughGltfTransform,
      onlyVisible: false,
      embedImages: true,
      trs: true
    }
  }

  runSceneResources (callback) {
    let exporter = new GLTFExporter()

    try {
      // Cleanup first before passing
      exporter.parse(this.scene, function (output) {
        let outputFiles = this.returnGltfFiles(output)
        callback(outputFiles.resources)
      }.bind(this), this.baseExportOptions)
    } catch (err) {
      console.log('ERROR on export', err)
    }
  }

  runScene (callback) {
    let exporter = new GLTFExporter()

    // Before going to export, make sure we have the scene
    // in it's non-sequencer applied state

    let cleanScene = this.gui.getCleanSceneForExport()

    if(cleanScene === null) {
      console.warn('Failed to get clean scene for export. Aborting')
      return false
    }

    try {
      // Cleanup first before passing
      exporter.parse(cleanScene, function (output) {
        let ret = {
          scene: null,
          resources: null,
          settings: this.gui.export()
        }

        let processedScene
        // if (this.runThroughGltfTransform) {
        //   processedScene = this.processGlbThroughGLTFTransform(output)
        // } else {
          processedScene = this.processGltfThroughInternalTransform(output)
        // }

        let cleanedOutput = this.prepSceneDataForApi(processedScene)
        ret.scene = cleanedOutput.scene
        ret.resources = cleanedOutput.resources
        ret.resourcesUpdated = cleanedOutput.resourcesUpdated // This is a special sub array. These resources have updated, so will always save

        callback(ret)
      }.bind(this), this.baseExportOptions)
    } catch (err) {
      console.warn('ERROR on export', err)
    }
  }

  /*
     * GLTF-Transform creates an object with properties for each
     * resource object. We would like these as an array,
     * with a key relating to their relationship back in the scene graph
     * so we can apply our uniqueness check directly, without
     * having to hit the api with any unnessecary items
     */
  prepSceneDataForApi (scene) {
    let resources = []
    let resourcesUpdated = []

    let imagesArray = scene.json.images

    Object.keys(scene.resources).forEach(key => {
      let file = scene.resources[key]

      if (typeof (file) === 'File') {
        file = arrayBufferToFile(resource, key, imagesArray)
      }

      // If this file is marked in our gui.changedTextures array,
      // make sure it's always saved. (Resources are only saved during full save events)
      let inChangeSet = false

      if (imagesArray !== undefined) {
        let image = imagesArray.find(el => el.uri === key)
        if (image !== undefined) {
          if (this.gui.changedTextures.indexOf(image.name) >= 0) {
            inChangeSet = true
          }
        }
      }

      this.gui.clearChangedTextures()

      if (inChangeSet) resourcesUpdated.push(file)
      else resources.push(file)
    })

    let type = 'model/gltf+json'
    let name = 'export-' + Date.now() + '.gltf'

    let blob = new Blob(
      [JSON.stringify(scene.json)], {
        type: type
      })
    let sceneFile = new File([blob], name)

    return {
      scene: sceneFile,
      resources: resources,
      resourcesUpdated: resourcesUpdated
    }
  }

  runSceneSettings (callback) {
    callback(this.gui.export())
  }

  runTimeline (callback) {
    let data = this.timeline.export()
    callback(data)
  }

  exportScene (scene, callback) {
    this.scene = scene
    return this.runScene(callback)
  }

  exportSceneResources (scene, callback) {
    this.scene = scene
    this.runSceneResources(callback)
  }

  exportSceneSettings (scene, callback) {
    this.scene = scene
    this.runSceneSettings(callback)
  }

  exportPreviz (timeline, callback) {
    this.timeline = timeline
    let data = this.timeline.export()
    if(callback) callback(data)
  }
  
  exportSequence (timeline, callback) {
    this.timeline = timeline
    this.runTimeline(callback)
  }

  returnGlbFile (buffer) {
    let type = 'application/octet-stream'
    let name = 'export-' + Date.now() + '.glb'

    let blob = new Blob([buffer], {
      type: type
    })
    return new File([blob], name)
  }

  processGltfThroughInternalTransform (output) {
    // Pull out the images
    let resources = []

    if (output.images !== undefined) {
      output.images.forEach((image, index) => {
        let key = 'texture-' + index

        if (image.name !== undefined) {
          key = image.name
        }

        if (image.mimeType === 'image/png') {
          key = key + '.png'
        } else {
          key = key + '.jpg'
        }

        let file = dataURLtoFile(image.uri, key)

        if(file !== null) {
          resources.push(file)
          output.images[index].uri = key
        }
      })
    }

    //  Also extract buffers
    if (output.buffers !== undefined) {
      output.buffers.forEach((buffer, index) => {
        let key = 'buffer-' + index + '.bin'

        let file = dataURLtoFile(buffer.uri, key)

        if(file !== null) {
          resources.push(file)
          output.buffers[index].uri = key
        }
      })
    }

    // let blob = new Blob([JSON.stringify(ret)], {
    //   type: type
    // })

    // let sceneFile = new File([blob], name)

    return {
      json: output,
      resources: resources
    }
  }

  // processGlbThroughGLTFTransform (glb) {
  //   const io = new WebIO()
  //   io.registerExtensions(KHRONOS_EXTENSIONS)
  //   const doc = io.unpackGLB(glb)

  //   let meshes = []

  //   // doc.getRoot().listMeshes().forEach(mesh => {
  //   //   let parents = mesh.listParents().filter((p) => p.propertyType !== 'Root')

  //   //   let parent = parents[0]
  //   //   // Just assume the first parent for naming purposes
  //   //   if (mesh.getName() === '') {
  //   //     mesh.setName(parent.getName())
  //   //   }
  //   //   meshes.push(mesh.getName())
  //   // })

  //   doc.getRoot().listMaterials().forEach(material => {
  //     // Go through the texture types and assign names
  //     let textures = []
  //     textures.push({
  //       type: 'map',
  //       texture: material.getBaseColorTexture()
  //     })
  //     textures.push({
  //       type: 'emissive',
  //       texture: material.getEmissiveTexture()
  //     })
  //     textures.push({
  //       type: 'roughness',
  //       texture: material.getMetallicRoughnessTexture()
  //     })
  //     textures.push({
  //       type: 'normal',
  //       texture: material.getNormalTexture()
  //     })
  //     textures.push({
  //       type: 'occulsion',
  //       texture: material.getOcclusionTexture()
  //     })

  //     textures.forEach(row => {
  //       if (row.texture === null) return

  //       // Only name the texture if it's not already got a name
  //       let name = row.texture.getName()
  //       if (name !== '') return

  //       row.texture.setName(material.getName() + '_' + row.type)
  //     })
  //   })

  //   // Split the single buffer into individual buffers for all meshes
  //   partition({
  //     meshes: meshes
  //   })(doc)

  //   // Deduplicate any repeated textures
  //   dedup({
  //     textures: true
  //   })(doc)

  //   // Sample and generate Ambient Occulsion maps
  //   // ao({ samples: 500 })(doc)

  //   const out = io.createNativeDocument(doc, {
  //     basename: 'export',
  //     isGLB: false
  //   })

  //   // Mark this export as through gltf-transform + previz
  //   out.json.asset.generator = out.json.asset.generator + ' + Previz'

  //   return out
  // }
}

function dataURLtoFile (dataurl, filename) {

  if(dataurl === null) {
    console.warn('[exporter] DataUrl to file attmepting to export null for ', filename)
    return
  }

  var arr = dataurl.split(',')
  var parts = arr[0].match(/:(.*?);/)

  if(parts === null || parts.length < 2) {
    console.warn('[exporter] Failed to get mime type from dataURL. Possibly bad data in export?', filename, dataurl)
    return null
  }

  var mime = parts[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, {
    type: mime
  })
}

function arrayBufferToFile (arraybuffer, filename, images) {
  // Find the base data for this item from the scene tree
  // Check images first

  let mime = 'application/octet-stream'

  if (images !== undefined) {
    let image = images.find(el => el.uri === filename)
    if (image !== undefined) mime = image.mimeType
  }

  return new File([arraybuffer], filename, {
    type: mime
  })
}
