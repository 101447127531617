
// Main webGL renderer class
export default class LoadingBar {
  constructor (container) {
    this.container = container
    this.state = 'ready'
    this.containerId = 'previz-state-msg-container'
    this.progress = 0
    this.busy = false

    this.base = null
    this.bar = null

    this.busyBase = null

    // this.startInit()
  }

  clear () {
    if (this.base !== null) {
      this.container.removeChild(this.base)
      this.base = null
    }

    if (this.busyBase !== null) {
      this.container.removeChild(this.busyBase)
      this.busyBase = null
    }
  }

  createBase () {
    let base = document.createElement('div')
    base.setAttribute('class', 'flex flex-col justify-center items-center px-6 -my-8 py-4 w-1/4 z-20')
    base.setAttribute('id', this.containerId)

    return base
  }

  startInit () {
    console.warn('Previz:Loadingbar: startInit has been depreciated. Use built in logging loading instead.')

    // this.clear()
    // this.state = 'init'

    // const baseBlock = document.createElement('div')
    // baseBlock.setAttribute('class', 'w-full')

    // const heading = document.createElement('div')
    // heading.setAttribute('class', 'text-2xl leading-none text-center text-white mb-4')
    // heading.innerHTML = 'PREVIZ'

    // const body = document.createElement('div')
    // body.setAttribute('class', 'text-xs leading-none font-mono text-center text-gray-500')
    // body.innerHTML = 'v4.0.beta2'

    // baseBlock.appendChild(heading)
    // baseBlock.appendChild(body)

    // this.base = this.createBase()
    // this.base.appendChild(baseBlock)
    // this.container.appendChild(this.base)
  }

  startLoading () {
    console.warn('Previz:Loadingbar: startLoading has been depreciated. Use built in logging loading instead.')

    // this.clear()
    // this.state = 'loading'

    // this.base = this.createBase()

    // const baseBar = document.createElement('div')
    // baseBar.setAttribute('class', 'shadow w-full bg-blue-900')

    // this.bar = document.createElement('div')
    // this.bar.setAttribute('class', 'bg-blue-500 text-xs leading-none text-center text-white')

    // baseBar.appendChild(this.bar)
    // this.base.appendChild(baseBar)

    // this.container.appendChild(this.base)
    // // this.redraw()
  }

  updatePc (pc) {
    console.warn('Previz:Loadingbar: updatePC has been depreciated. Use built in logging loading instead.')

    // if (pc > 0 && pc <= 100) this.progress = pc
    // if (pc > 100) this.progress = 100

    // this.redraw()
  }

  redraw () {
    const style = 'height:3px; width: ' + this.progress + '%'
    this.bar.setAttribute('style', style)
  }

  startBusy () {
    console.warn('Previz:Loadingbar: startBusy has been depreciated. Use built in logging loading instead.')

    // this.clear()

    // this.state = 'busy'
    // this.busy = true

    // this.busyBase = this.createBase()
    // const baseBar = document.createElement('div')
    // baseBar.setAttribute('class', 'shadow w-full bg-green-900')

    // this.bar = document.createElement('div')
    // this.bar.setAttribute('class', 'bg-green-500 text-xs leading-none text-center text-white py-1 px-2')
    // this.bar.innerText = 'Working'

    // baseBar.appendChild(this.bar)
    // this.busyBase.appendChild(baseBar)

    // this.container.appendChild(this.busyBase)

    // const style = 'height:20px; width: 100%'
    // this.bar.setAttribute('style', style)
  }

  endBusy () {
    console.warn('Previz:Loadingbar: endBusy has been depreciated. Use built in logging loading instead.')

    // this.state = 'ready'
    // this.busy = false
    // this.clear()
  }
}
