import Helpers from '../../utils/helpers'
import Config from './../../data/config'

// Main settings class
export default class Settings {
  constructor (settings) {
    this.settings = {}
    if (settings !== undefined) {
      this.settings = settings
    }
  }

  get (key) {
    if (this.settings.hasOwnProperty(key)) return this.settings[key]
    return Helpers.getByDot(Config, key)
  }
}
