import Detector from './src/utils/detector'
import Main from './src/app/main'

class PrevizViewer {
  constructor (scene, resources, containerId) {
    this.core = null

    if (!Detector.webgl) {
      Detector.addGetWebGLMessage()
    } else {
      let id = 'appContainer'
      if (containerId !== undefined) {
        id = containerId
      }

      const container = document.getElementById(id)

      this.core = new Main(container)
      this.core.loadScene(scene, resources)
    }
  }
}

class PrevizSequenceViewer {
  constructor (previz, resources, containerId) {
    this.core = null

    if (!Detector.webgl) {
      Detector.addGetWebGLMessage()
    } else {
      let id = 'appContainer'
      if (containerId !== undefined) {
        id = containerId
      }

      const container = document.getElementById(id)

      this.core = new Main(container)

      let world = {}
      if (previz.world !== undefined && previz.world !== null) world = previz.world
      this.core.loadWorld(world, resources)
      this.core.loadSequence(previz, resources)
      this.core.sequence.timeline.setReady()
    }
  }
}

export { PrevizSequenceViewer, PrevizViewer }

export default PrevizViewer
