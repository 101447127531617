import { MathUtils } from '../utils/math.js'
import { Sequence } from './Sequence.js'

function Sequences () {
  this.name = 'Sequences'
  this.type = 'SequenceBaseGroup'
  this.uuid = MathUtils.generateUUID()

  this.children = [
    new Sequence()
  ]
}

Sequences.prototype = Object.assign({

  constructor: Sequences,

  isSequences: true,

  toJSON: function ( meta ) {

    const output = {}
    const object = {}

    output.metadata = {
      version: 0.1,
      type: this.type,
      generator: 'Sequences.toJSON'
    }


    object.uuid = this.uuid
    object.type = this.type
    object.name = this.name

    if(this.children.length > 0) {
      object.children = []

      this.children.forEach(child => {
        object.children.push(child.toJSON().object)
      })
    }

    output.object = object

    return output
  },

  get childCount () {
    return this.children.length
  },

  createChild () {

    let child = new Sequence()

    child.name = 'Sequence ' + (this.childCount + 1)

    this.children.push(child)

    return child
  }
})

export { Sequences }
