import TWEEN from '@tweenjs/tween.js'

// This object contains the state of the app
export default {
  isDev: true,
  isShowingStats: true,
  isLoaded: false,
  isTweening: false,
  isRotating: true,
  isMouseMoving: false,
  isMouseOver: false,

  maxAnisotropy: 1,
  dpr: 1,
  maxFps: 30,
  easing: TWEEN.Easing.Quadratic.InOut,
  duration: 500,
  texture: {
    path: '/static/textures/',
    imageFiles: [
      { name: 'UV', image: 'UV_16x9.png' }
    ]
  },
  mesh: {
    enableHelper: true,
    wireframe: false,
    translucent: true,
    material: {
      color: 0xffffff,
      emissive: 0xffffff
    }
  },
  global: {
    clearColor: 0x333333,
    fog: {
      color: 0x333333,
      near: 0.0001
    }
  },
  camera: {
    fov: 60,
    near: 0.0001,
    far: 10000,
    aspect: 1,
    posX: -25,
    posY: 25,
    posZ: 100
  },
  // controls: {
  //   autoRotate: false,
  //   autoRotateSpeed: -2.5,

  //   rotateSpeed: 0.15,
  //   zoomSpeed: 0.8,
  //   panSpeed: 0.2,
  //   enableDamping: true,
  //   screenSpacePanning: true,
  //   dampingFactor: 0.25,

  //   minDistance: 0.1,
  //   maxDistance: 5000,
  //   minPolarAngle: Math.PI / 5,
  //   maxPolarAngle: Math.PI / 2,
  //   minAzimuthAngle: -Infinity,
  //   maxAzimuthAngle: Infinity,
  //   enableZoom: true,
  //   target: {
  //     x: 0,
  //     y: 0,
  //     z: 0
  //   }
  // },
  ambientLight: {
    enabled: true,
    color: 0xfff3ed,
    intensity: 1
  },
  directionalLight: {
    enabled: true,
    color: 0xf0f0f0,
    intensity: 1.0,
    castShadow: true,
    x: -75,
    y: 280,
    z: 150
  },
  shadow: {
    enabled: true,
    helperEnabled: true,
    bias: 0,
    mapWidth: 2048,
    mapHeight: 2048,
    near: 250,
    far: 400,
    top: 100,
    right: 100,
    bottom: -100,
    left: -100
  },
  pointLight: {
    enabled: true,
    color: 0xffffff,
    intensity: 4,
    distance: 115,
    castShadow: true,
    x: 0,
    y: 25,
    z: 30
  },
  hemiLight: {
    enabled: true,
    color: 0xc8c8c8,
    groundColor: 0xffffff,
    intensity: 1,
    x: 0,
    y: 0,
    z: 0
  }
}
