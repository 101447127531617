
// Main sequence class
export default class Player {
  constructor () {
    this._isPlaying = false
    this.currentTime = 0
    this._playbackRate = 1
  }

  get isPlaying () {
    return this._isPlaying
  }

  set isPlaying (value) {
    this._isPlaying = value
  }

  togglePlay () {
    if (this.isPlaying) this.pause()
    else this.play()
  }

  play () {
    this.isPlaying = true
  }

  pause () {
    this.isPlaying = false
  }

  stopAndJumpToStart () {
    this.pause()
    this.currentTime = 0
  }

  jumpToStart () {
    this.currentTime = 0
  }

  tick (delta) {
    if (this.isPlaying) {
      this.currentTime += (delta) * this.playbackRate
    }
  }

  get playbackRate () {
    return Number.parseFloat(this._playbackRate)
  }

  set playbackRate (value) {
    let v = Number.parseFloat(value).toFixed(1)
    if (v > 0 && v <= 10) this._playbackRate = v
  }

  decreasePlaybackRate () {
    let jump = 0
    if (this.playbackRate > 5) jump = 1
    else if (this.playbackRate > 2) jump = 0.5
    else if (this.playbackRate > 0.2) jump = 0.2

    this.playbackRate = this.playbackRate - jump
  }

  increasePlaybackRate () {
    let jump = 0.1
    if (this.playbackRate >= 5) jump = 1
    else if (this.playbackRate >= 2) jump = 0.5
    else if (this.playbackRate >= 0.2) jump = 0.2

    this.playbackRate = this.playbackRate + jump
  }

  jumpToTime (time) {
    let val = Number.parseFloat(time)
    this.currentTime = val
  }
}
