/* global pino */
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import Helpers from '../../utils/helpers'

export default class Loader {
  constructor(manager, scene, loadingbar, core) {
    this.texturePath = ''
    this.manager = manager
    this.scene = scene
    this.loadingbar = loadingbar
    this.core = core
    this.animations = []
    this.missing = []

    this.filemap = {}
    this.addDemoFiles()

    this.manager.setURLModifier((url) => {
      // The url comes in like {{ baseUrl }}/{{ key }}
      // ie. https://s3.path.com/project/1234/buffer-0.bin
      // we just want the last 'buffer-0.bin' to compare against
      let key = url.substring(url.lastIndexOf('/') + 1)

      // A straight match. The simplest case
      if (this.filemap[key] !== undefined) {
        return this.filemap[key]
      }

      // Handled malformed keys - extensions might be swapped or excluded
      // ie. 'room_ao.png' > 'room_ao.jpg' > 'room_ao'
      if (key.includes('.jpg') || key.includes('.png')) {
        let matchable = []
        if (key.includes('.jpg')) {
          matchable.push(key.replace('.jpg', '.png'))
          matchable.push(key.replace('.jpg', ''))
        }
        if (key.includes('.png')) {
          matchable.push(key.replace('.png', '.jpg'))
          matchable.push(key.replace('.png', ''))
        }

        let found = null
        matchable.forEach((adjustedKey) => {
          if (this.filemap[adjustedKey] !== undefined) {
            found = this.filemap[adjustedKey]
          }
        })

        if (found !== null) {
          return found
        }
      }

      if (url.startsWith('blob:')) return url // This loaded from a local file
      if (url.includes('.amazonaws.com')) return url // This already was encoded for a remote location

      if (url.includes('.bin')) {
        console.warn(
          '[loader] Failed to find valid url for requested path.(' +
            url +
            '). Returning default buffer instead'
        )
        this.markMissingResource(url)

        return '/static/fallback/buffer.bin'
      }

      if (url.includes('.jpg') || url.includes('.png')) {
        console.warn(
          '[loader] Failed to find valid url for requested path.(' +
            url +
            '). Returning default image texture instead'
        )

        this.markMissingResource(url)
        return '/static/fallback/texture.png'
      }

      return url
    })
  }

  markMissingResource(key) {
    this.missing.push(key)
    pino.info({
      error: true,
      resolveable: true,
      key: key,
      type: 'loadertexture',
      message: '(' + key + ') resource missing'
    })
  }

  addDemoFiles() {
    let resources = []
    this.updateFileMap(resources)
  }

  setResources(resources) {
    if (resources !== undefined) this.updateFileMap(resources)
  }

  loadSrc(src, callback, settings) {
    let type = 'url'
    let name = 'Model'

    if (typeof src !== 'string') type = 'file'

    if (type === 'file') {
      name = src.name
      var extension = src.name.split('.').pop().toLowerCase()

      var reader = new window.FileReader()

      reader.addEventListener('load', (event) => {
        this.handleLoaded(
          extension,
          event.target.result,
          name,
          callback,
          settings
        )
      })

      reader.readAsArrayBuffer(src)
    } else {
      name = this.getFilenameFromUrl(src)
      let extension = this.getExtFromUrl(src)

      if (extension.substring(0, 5) === 'com/p') {
        extension = 'glb' // Fix to load previz_link files for demo
      }

      var loader = new THREE.FileLoader(this.manager)
      loader.setResponseType('arraybuffer')
      loader.load(
        src,
        (obj) => {
          this.handleLoaded(extension, obj, name, callback, settings)
        },
        Helpers.logProgress(this.loadingbar),
        Helpers.logError()
      )
    }
  }

  getExtFromUrl(url) {
    return url.split(/#|\?/)[0].split('.').pop().trim()
  }

  getFilenameFromUrl(url) {
    return url.split(/#|\?/)[0].trim()
  }

  handleLoaded(extension, contents, name, callback, settings) {
    var loader = null

    switch (extension) {
      case 'usdz':
        var usdzObject = new USDZLoader(this.manager).parse(contents)
        usdzObject.name = name
        this.appendToScene(usdzObject, settings)
        return callback()

      case 'glb':
      case 'gltf':
        loader = new GLTFLoader(this.manager)
        var dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/static/libs/draco/gltf/')
        loader.setDRACOLoader(dracoLoader)

        loader.parse(
          contents,
          '',
          (result) => {
            // Previz uses GLTF scenes as it's internal format
            // When the scene is exported it's wrapped in a root level scene
            // To prevent each export/save nesting the objects deeper
            // we simply append each level 1 node to our existing scene
            // This lets users drop in new gltf scenes as nodes on the tree
            // but also load previz scenes in the correct format
            if (this.isScenePrevizGenerated(result.asset)) {
              result.scene.children.forEach((child) => {
                this.appendToScene(child, settings)
              })
            } else {
              this.appendToScene(result.scene, settings)
            }

            this.addAnimations(result.animations)
            return callback()
          },
          (error) => {
            console.warn('GLTF Loader failed to parse with error', error)
          }
        )
        break
      case 'fbx':
        let result = new FBXLoader(this.manager).parse(contents)
        result.name = name
        this.addAnimations(result.animations)
        this.appendToScene(result, settings)

        return callback()

      case 'obj':
        let string = contents
        if (contents instanceof ArrayBuffer) {
          const textDecoder = new TextDecoder('utf-8')
          string = textDecoder.decode(contents)
        }

        var object = new OBJLoader(this.manager).parse(string)
        object.name = name
        this.appendToScene(object, settings)

        return callback()

      case 'ply':
        var geometry = new PLYLoader(this.manager).parse(contents)
        geometry.sourceType = 'ply'
        geometry.sourceName = name

        var material = new THREE.MeshStandardMaterial({
          color: '0xFFFFFF',
          flatShading: true,
          roughness: 1,
          metalness: 0,
          side: THREE.DoubleSide
        })

        material.name = name + '_mat'
        var mesh = new THREE.Mesh(geometry, material)
        mesh.name = name

        this.appendToScene(mesh, settings)

        return callback()

      default:
        pino.info('Unsupported file format (' + extension + ').')
        break
    }
  }

  isScenePrevizGenerated(asset) {
    if (asset !== undefined && asset.generator !== undefined) {
      if (asset.generator.includes('Previz')) return true
    }

    return false
  }

  updateFileMap(resources) {
    resources.forEach((res) => {
      if (this.filemap[res.key] === undefined) this.filemap[res.key] = res.url
    })
  }

  addToFileMap(key, url) {
    this.updateFileMap([
      {
        key: key,
        url: url
      }
    ])

    // Remove from missing array if marked
    let existingIndex = this.missing.findIndex((row) => row === key)
    this.missing.splice(existingIndex, 1)
  }

  addAnimations(animations) {
    if (animations !== undefined) {
      animations.forEach((animation) => {
        this.animations.push(animation)
      })
    }
  }

  appendToScene(object, settings) {
    if (object.name === 'Global_Lights') return

    let names = []

    this.scene.traverse((child) => {
      names.push({
        id: child.uuid,
        name: child.name,
        type: 'mesh'
      })

      if (child.material !== undefined) {
        if (Array.isArray(child.material)) {
          child.material.forEach((mat) => {
            names.push({
              id: mat.uuid,
              name: mat.name,
              type: 'material'
            })
          })
        } else {
          names.push({
            id: child.material.uuid,
            name: child.material.name,
            type: 'material'
          })
        }
      }
    })

    function enforceUniqueName(uuid, name, type, i) {
      if (i === undefined) i = 0
      else i = i + 1

      let candidate = name
      if (i > 0) candidate = name + '.' + i

      let existing = names.find((row) => row.id === uuid)
      if (existing !== undefined) {
        return existing.name
      }

      let index = names.findIndex(
        (row) => row.name === candidate && row.type === type
      )
      if (index > -1) {
        return enforceUniqueName(uuid, name, type, i)
      }

      names.push({
        id: uuid,
        name: candidate,
        type: type
      })
      return candidate
    }

    object.traverse((child) => {
      if (child.name !== '') {
        if (child.userData !== undefined) {
          if (child.userData.name !== undefined) {
            // child.name = child.userData.name
          }
        }

        if (child.userData.visible !== undefined) {
          if (child.userData.visible === false) {
            child.visible = false
          }
        }

        // Check if this name exists in set, and make unique if it does
        child.name = enforceUniqueName(child.uuid, child.name, 'mesh')

        // Also check material while we're here
        if (child.material !== undefined) {
          if (Array.isArray(child.material)) {
            child.material.forEach((mat) => {
              mat.name = enforceUniqueName(mat.uuid, mat.name, 'material')
            })
          } else {
            child.material.name = enforceUniqueName(
              child.material.uuid,
              child.material.name,
              'material'
            )
          }
        }
      }
    })

    // Apply any settings on the object
    object = this.applySettings(object, settings)
    this.scene.add(object)
    if (this.core.sequence && this.core.sequence.timeline) {
      this.core.sequence.timeline.resetBlankMeshes()
    }
  }

  applySettings(object, settings) {
    if (settings === null || settings === undefined) return object

    if (settings.scale !== undefined) {
      object.scale.x = settings.scale.x
      object.scale.y = settings.scale.y
      object.scale.z = settings.scale.z
    }

    if (settings.position !== undefined) {
      object.position.x = settings.position.x
      object.position.y = settings.position.y
      object.position.z = settings.position.z
    }

    if (settings.rotation !== undefined) {
      object.rotation.x = settings.rotation.x
      object.rotation.y = settings.rotation.y
      object.rotation.z = settings.rotation.z
    }
    return object
  }
}
