/* global pino */
import * as THREE from 'three'
import Config from '../../data/config'
import { BloomEffect, EffectComposer, EffectPass, RenderPass } from 'postprocessing'
import { SSREffect } from '../utils/ssr.js'

// Main webGL renderer class
export default class Renderer {
  constructor (scene, container) {
    // Properties
    this.scene = scene
    this.container = container
    this.resizeTimeout = null
    this.quality = 'high'
    this.autoQuality = true
    this.nativeQuality = null
    this.camera = null
    this._enablePostProcessing = true
    this.clock = new THREE.Clock()

    this.multisamplingPasses = 2
    this.effects = {
      bloom: {
        enabled: false,
        smoothing: 0.025,
        threshold: 0.9,
        intensity: 1
      },
      SSR: {
        enabled: false
      }

    }

    let canvas = document.createElement('canvas')
    canvas.setAttribute('class', 'absolute')

    // Create WebGL renderer and set its antialias
    this.threeRenderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      logarithmicDepthBuffer: true,
      // depth: false,
      powerPreference: 'high-performance',
      // failIfMajorPerformanceCaveat: true,
      stencil: false,
      preserveDrawingBuffer: true
    })

    this.threeRenderer.setClearColor(Config.global.clearColor)

    // this.threeRenderer.setPixelRatio(window.devicePixelRatio) // For retina
    // this.threeRenderer.gammaOutput = true
    this.threeRenderer.outputEncoding = THREE.sRGBEncoding
    this.threeRenderer.physicallyCorrectLights = true
    this.threeRenderer.gammaFactor = 2.2
    this.threeRenderer.toneMapping = THREE.LinearToneMapping
    this.threeRenderer.toneMappingExposure = Math.pow(1, 5.0) // to allow for very bright scenes.

    // Appends canvas
    container.appendChild(this.threeRenderer.domElement)

    // Shadow map options
    this.threeRenderer.shadowMap.enabled = false
    this.threeRenderer.shadowMap.type = THREE.PCFSoftShadowMap

    // Get anisotropy for textures
    Config.maxAnisotropy = this.threeRenderer.capabilities.getMaxAnisotropy()

    // Post Processing
    this.composer = null
    this.outlinePass = null

    // Initial size update set to canvas container
    this.updateSize()
    this.updateQuality()
  }

  setupPostProcessing (camera) {
    // postprocessing
    this.camera = camera
    this.composer = new EffectComposer(this.threeRenderer, { multisampling: this.multisamplingPasses })
    let size = this.updateSize()
    this.composer.setSize(size.width, size.height)

    this.updatePostProcessingSettings()
  }

  addRenderPass () {
    let renderPass = new RenderPass(this.scene, this.camera)
    this.composer.addPass(renderPass)
  }

  updatePostProcessingSettings () {
    this.composer.reset()

    this.addRenderPass()

    if (this.enablePostProcessing) {
      if (this.effects.bloom.enabled) {
        const bloomEffect = new BloomEffect({
          luminanceThreshold: this.effects.bloom.threshold,
          luminanceSmoothing: this.effects.bloom.smoothing
        })

        const effectPassA = new EffectPass(this.camera, bloomEffect)
        this.composer.addPass(effectPassA)
      }

      if (this.effects.SSR.enabled) {
        const ssrEffect = new SSREffect(this.scene, this.camera)
        const ssrPass = new EffectPass(this.camera, ssrEffect)
        this.composer.addPass(ssrPass)
      }
    }
  }

  changeQuality (quality) {
    if (quality === 'auto') {
      this.autoQuality = true
      // @todo - cycle to whatever auto adjust thinks is best
    } else {
      this.autoQuality = false
      this.quality = quality
      this.updateQuality()
    }
  }

  cycleQuality () {
    this.autoQuality = false
    switch (this.quality) {
      case 'low':
        this.quality = 'mid'
        break
      case 'mid':
        this.quality = 'high'
        break
      case 'high':
        this.quality = 'low'
        break
    }

    this.updateQuality()
  }

  downgradeQuality () {
    this.nativeQuality = this.quality

    this.quality = 'low'
    this.updateQuality()
  }

  upgradeQuality () {
    if (this.nativeQuality !== null) {
      this.quality = this.nativeQuality
      this.updateQuality()

      this.nativeQuality = null
      return true
    }

    return false
  }

  updateQuality () {
    pino.info('Render quality changed to : ' + this.quality)

    if (this.quality === 'low') {
      this.threeRenderer.setPixelRatio(0.5)
      this.multisamplingPasses = 1
    } else if (this.quality === 'high') {
      this.threeRenderer.setPixelRatio(window.devicePixelRatio)
      this.multisamplingPasses = 4
    } else {
      this.threeRenderer.setPixelRatio(1)
      this.multisamplingPasses = 2
    }

    if (this.composer !== null) {
      this.composer.multisampling = this.multisamplingPasses
    }
  }

  updateSize () {
    const height = this.container.offsetHeight
    const width = this.container.offsetWidth

    this.threeRenderer.setSize(width, height)

    return {
      width: width,
      height: height
    }
  }

  get postProcessingAvailable () {
    return this.composer !== null
  }

  get postProcessingEnabled () {
    return this.postProcessingAvailable && this.enablePostProcessing === true
  }

  get enablePostProcessing () {
    return this._enablePostProcessing
  }

  set enablePostProcessing (value) {
    // Kill and rebuild the render state
    this._enablePostProcessing = value
    this.updatePostProcessingSettings()
  }

  render (scene, camera) {
    if (this.composer !== null) {
      this.composer.render(this.clock.getDelta())
    } else {
      this.threeRenderer.render(scene, camera)
    }
  }

  getSize (target) {
    return this.threeRenderer.getSize(target)
  }
}
