import * as THREE from 'three'
import Loader from './loader'
import DefaultScene from '../helpers/defaultScene'
import Light from '../helpers/light'

export default class Model {
  constructor(scene, manager, loadingbar, resources, core) {
    this.scene = scene
    this.animations = []
    this.loader = new Loader(manager, scene, loadingbar, core)
    this.loader.animations = this.animations
    this.loader.setResources(resources)

    this.sceneObjects = {}
    this.envObjects = {}
  }

  clear() {
    this.sceneObjects = {}
    this.envObjects = {}
  }

  loadFallbackContent() {
    if (this.scene === undefined) return
    const defaultScene = new DefaultScene(this.scene)
    defaultScene.setup()
  }

  loadFallback(callback) {
    const defaultScene = new DefaultScene(this.scene)
    defaultScene.setup()
    this.addLights()
    this.traverseScene()
    callback()
  }

  addLights() {
    let light = new Light(this.scene)
    // light.addGlobalLights(); fucks up the focus on the geometry, have to pan around to find my model
    light.addAmbientLight()
    // light.addPointLight();
    light.addDirectionalLight()
  }

  load(src, callback, settings) {
    if (src === null) {
      this.loadFallback(callback)
      return
    }

    this.loader.loadSrc(
      src,
      function () {
        this.traverseScene()
        this.addLights()
        callback()
      }.bind(this, callback),
      settings
    )
  }

  traverseScene() {
    let sceneLights = []
    let sceneCameras = []
    let sceneMeshes = []
    let cameraHelpersToBeAdded = []

    this.scene.traverse((child) => {
      if (child instanceof THREE.PerspectiveCamera) {
        sceneCameras.push({
          name: child.name
        })
        cameraHelpersToBeAdded.push(child)
      }

      if (child instanceof THREE.Mesh) {
        let uvs = this.extractUVNamesFromAttributes(child.geometry.attributes)

        sceneMeshes.push({
          name: child.name,
          uvs: uvs
        })
      }

      if (
        child instanceof THREE.SpotLight ||
        child instanceof THREE.AmbientLight ||
        child instanceof THREE.DirectionalLight ||
        child instanceof THREE.HemisphereLight ||
        child instanceof THREE.PointLight ||
        child instanceof THREE.RectAreaLight
      ) {
        sceneLights.push({
          name: child.name,
          type: child.type
        })
      }
    })

    // visualize camera frustum for each camera in the scene
    /*
    cameraHelpersToBeAdded.forEach((camera) => {
      let cameraVisualizer = new THREE.CameraHelper(camera)
      cameraVisualizer.name = `CameraHelper_${camera.name}`
      cameraVisualizer._ignoreSceneGraph = true // gets excluded from scene tree
      this.scene.add(cameraVisualizer)
    })
    */

    // Set prop to obj so it can be accessed from outside the class
    this.sceneObjects.lights = sceneLights
    this.sceneObjects.cameras = sceneCameras
    this.sceneObjects.meshes = sceneMeshes
    // Attach this to the scene so we can access it upstream
    this.scene._sceneObjects = this.sceneObjects
  }

  extractUVNamesFromAttributes(attributes) {
    if (attributes === undefined) return []

    let uvs = []

    // 8 is the max UVs most formats support
    // the specific name depends on the inbound format
    let possibles = [
      'uv',
      'uv1',
      'uv2',
      'uv3',
      'texcoord_2',
      'texcoord_3',
      'texcoord_4',
      'texcoord_5',
      'texcoord_6',
      'texcoord_7',
      'texcoord_8',
      '_uv1',
      '_uv2',
      '_uv3',
      '_uv4',
      '_uv5',
      '_uv6',
      '_uv7',
      '_uv8'
    ]

    possibles.forEach((possible) => {
      if (attributes.hasOwnProperty(possible)) {
        uvs.push(possible)
      }
    })
    return uvs
  }

  get groundPlaneVisibility() {
    return this.envObjects.ground !== undefined
  }

  setGroundPlaneVisibility(value) {
    // @todo
    // if (value) {
    //   this.addGroundPlane()
    // } else {
    //   this.removeGroundPlane()
    // }
  }
}
