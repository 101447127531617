import enableInlineVideo from 'iphone-inline-video'
import * as THREE from 'three'
import Hls from 'hls.js'
import BaseResource from './BaseResource'

export default class VideoResource extends BaseResource {
  constructor(object) {
    super(object)
    this.type = 'video'
    this.domElement = null

    this._waiting = true
  }

  get duration() {
    if (this.domElement !== null) {
      return this.domElement.duration
    }
    return null
  }

  play() {
    if (this.domElement !== null) {
      this.domElement.play()
    }
  }

  pause() {
    if (this.domElement !== null) {
      this.domElement.pause()
    }
  }

  seek(t) {
    if (this.domElement !== null) {
      // While the underlying video is still loading, duration returns NaN
      if (!isNaN(this.duration)) {
        t = t % this.duration
      }

      this.domElement.currentTime = t
      this.texture.needsUpdate = true
    }
  }

  load() {
    let callbacks = ['progresss', 'canplaythrough']

    let src = null
    if (this.url !== null) {
      src = this.url
    } else if (this.file !== null) {
      src = this.file
    }

    let domElement = this.buildVideo(src, callbacks)

    var texture = new THREE.VideoTexture(domElement)
    texture.minFilter = THREE.LinearFilter
    texture.magFilter = THREE.LinearFilter
    texture.format = THREE.RGBAFormat

    if (
      this.settings !== null &&
      this.settings !== undefined &&
      this.settings.flipY !== undefined
    )
      texture.flipY = this.settings.flipY
    this.texture = texture
    this.domElement = domElement
    this._state = 'loading'
  }

  eventCallback(event) {
    switch (event.type) {
      case 'canplaythrough':
        this._state = 'loaded'
        this.handleLoadedProgress()
        break
      case 'progress':
        this.handleLoadingProgress()
        break
      case 'stalled':
        this.handleStalled()
        break
      case 'playing':
        this.handlePlaying()
        break
      case 'pause':
        this.handlePause()
        break
      case 'waiting':
        this.handleWaiting()
        break
    }
  }

  handleWaiting() {
    this.emitter.emit('blocked')
    this._waiting = true
  }

  handlePause() {
    if (this._waiting === true) {
      this.emitter.emit('unblocked')
      this._waiting = false
    }
  }

  handlePlaying() {
    if (this._waiting === true) {
      this.emitter.emit('unblocked')
      this._waiting = false
    }
  }

  handleStalled() {
    this.emitter.emit('blocking')
    this._waiting = true
  }

  handleLoadingProgress() {
    this.emitter.emit('refresh')
  }

  handleLoadedProgress() {
    this.emitter.emit('loaded')
  }

  buildVideo(src, callbacks) {
    var video = document.createElementNS(
      'http://www.w3.org/1999/xhtml',
      'video'
    )
    video.crossOrigin = 'anonymous'
    video.muted = false
    video.loop = true
    video.preload = 'auto'

    let events = [
      'canplay',
      'loadstart',
      'canplaythrough',
      'progress',
      'playing',
      'stalled',
      'waiting'
    ]
    events.forEach((event) => {
      video.addEventListener(event, (evt) => {
        this.eventCallback(evt)
      })
    })
    video.playsInline = true

    enableInlineVideo(video, {
      iPad: true
    })

    // Check if this looks like a hls stream?
    let useHls = false
    if (src.indexOf('.m3u8') > 0) useHls = true

    if (useHls) {
      if (Hls.isSupported()) {
        let hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)

        return video
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        console.warn('HLS is supported failed, but native support')
        // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
        // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
        // This is using the built-in support of the plain video element, without using hls.js.
        video.src = src
        video.addEventListener('canplay', function () {
          video.load()
        })

        return video
      } else {
        console.error('HLS NOT supported but required for video asset.')
        return null
      }
    } else {
      video.src = src
      video.load()
      return video
    }
  }

  get bufferState() {
    return 'VIDEOVAL'
  }
}
