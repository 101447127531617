import { EventEmitter } from 'events'
import VideoResource from '../../models/VideoResource'
import ImageResource from '../../models/ImageResource'

export default class Resources {
  constructor () {
    this._resources = []
    this.emitter = new EventEmitter()
  }

  get items () {
    return this._resources
  }

  getResource (id) {
    return this._resources.find(row => row.id === id)
  }

  load (resources) {
    if (!Array.isArray(resources)) resources = [resources]

    let v = null
    let ret = []

    resources.forEach(resource => {
      let settings = null

      v = null
      if (resource !== null) {
        if (resource.settings !== undefined) settings = resource.settings

        if (resource.type === 'image') {
          v = new ImageResource(resource)
        }

        if (resource.type === 'video') {
          v = new VideoResource(resource)
        }
      }
      if (v !== null) {
        // Attach our listeners
        v.emitter.on('blocked', () => {
          this.emitter.emit('blocked', v.id)
        })
        v.emitter.on('unblocked', () => {
          this.emitter.emit('unblocked', v.id)
        })
        v.emitter.on('loaded', () => {
          this.emitter.emit('loaded', v.id)
        })
        v.emitter.on('repainted', (id) => {
          this.emitter.emit('repainted', id)
        })
        v.emitter.on('refresh', () => {
          this.emitter.emit('refresh')
        })

        this._resources.push(v)
        ret.push(v)
      }
    })

    this.loadFiles()
    return ret
  }

  loadFiles () {
    this._resources.forEach(item => {
      item.load()
    })
  }
}
