import * as THREE from 'three'

import Config from '../../data/config'

// Sets up and places all lights in scene
export default class Light {
  constructor (scene) {
    this.scene = scene
    this.lightGroupName = 'Global_Lights'
    this.global_ambient_name = 'global_ambient_light'
    this.global_hemi_name = 'global_hemisphere_light'
  }
  //   addDirectionalLight (params) {
  //     let group = this.scene.getObjectByName(this.lightGroupName)

  //     // Front upper left
  //     let color = '#ffe6ae'
  //     let intensity = 3
  //     let light = new THREE.DirectionalLight(color, intensity)
  //     light.name = 'light_front_upper'
  //     light.position.set(7.5, 5, 10)
  //     light.castShadow = true
  //     // Set up shadow properties for the light
  //     light.shadow.mapSize.width = 3000
  //     light.shadow.mapSize.height = 3000
  //     // light.shadow.camera.near = 0.5 // default
  //     // light.shadow.camera.far = 500 // default
  //     group.add(light)

  //     let color2 = '#ffe6ae'
  //     let intensity2 = 5
  //     let light2 = new THREE.DirectionalLight(color2, intensity2)
  //     light2.name = 'light_front_left'
  //     light2.position.set(-2.5, 3, 10)
  //     light2.castShadow = true
  //     light2.shadow.mapSize.width = 3000
  //     light2.shadow.mapSize.height = 3000
  //     group.add(light2)

  //     let color3 = '#ffe6ae'
  //     let intensity3 = 2
  //     let light3 = new THREE.DirectionalLight(color3, intensity3)
  //     light3.name = 'light_rear_lower'
  //     light3.position.set(-2.5, 1, -5)
  //     light3.castShadow = true
  //     light3.shadow.mapSize.width = 3000
  //     light3.shadow.mapSize.height = 3000
  //     group.add(light3)
  //   }

  get ambientLight () {
    let obj = this.scene.getObjectByName(this.global_ambient_name)
    if (obj === undefined) {
      // Make it (but hidden)
      obj = this.addAmbientLight()
    }

    return obj
  }

  addAmbientLight () {
    let group = this.findOrCreateLightGroup()

    var light = new THREE.AmbientLight(
      Config.ambientLight.color,
      Config.ambientLight.intensity
    )

    light.name = this.global_ambient_name
    light._isGlobalLight = true

    group.add(light)

    return light
  }

  get hemiLight () {
    let obj = this.scene.getObjectByName(this.global_hemi_name)
    if (obj === undefined) {
      // Make it (but hidden)
      obj = this.addHemiLight()
    }

    return obj
  }

  addPointLight () {
    var light = new THREE.PointLight(
      Config.pointLight.color,
      Config.pointLight.intensity
    )
    let group = this.findOrCreateLightGroup()
    group.add(light)
  }

  addDirectionalLight () {
    const { x, y, z, color, intensity } = Config.directionalLight
    const directionalLight = new THREE.DirectionalLight(color, intensity)
    directionalLight.position.set(x, y, z)
    let group = this.findOrCreateLightGroup()
    group.add(directionalLight)
  }

  addHemiLight () {
    let group = this.findOrCreateLightGroup()

    var light = new THREE.HemisphereLight(
      Config.hemiLight.color,
      Config.hemiLight.groundColor,
      Config.hemiLight.intensity
    )
    light.position.set(
      Config.hemiLight.x,
      Config.hemiLight.y,
      Config.hemiLight.z
    )
    light.visible = false
    light.name = this.global_hemi_name
    light._isGlobalLight = true

    group.add(light)

    return light
  }

  addGlobalLights () {
    // let group = this.findOrCreateLightGroup();
    // // Ambient
    // if (this.scene.getObjectByName(this.global_ambient_name) === undefined) {
    //   var ambient = new THREE.AmbientLight(
    //     Config.ambientLight.color,
    //     Config.ambientLight.intensity
    //   );
    //   ambient.visible = Config.ambientLight.enabled;
    //   ambient.name = this.global_ambient_name;
    //   this.ambientLight = ambient;
    //   group.add(ambient);
    // }
    // // Hemisphere light
    // if (this.scene.getObjectByName(this.global_hemi_name) === undefined) {
    //   var hemi = new THREE.HemisphereLight(
    //     Config.hemiLight.color,
    //     Config.hemiLight.groundColor,
    //     Config.hemiLight.intensity
    //   );
    //   hemi.position.set(
    //     Config.hemiLight.x,
    //     Config.hemiLight.y,
    //     Config.hemiLight.z
    //   );
    //   hemi.visible = Config.hemiLight.enabled;
    //   hemi.name = this.global_hemi_name;
    //   this.globalHemi = hemi;
    //   group.add(hemi);
    // }
  }

  findOrCreateLightGroup () {
    let group = this.scene.getObjectByName(this.lightGroupName)

    if (group === undefined) {
      group = new THREE.Group()
      group.name = this.lightGroupName
      group._ignoreExport = true

      this.scene.add(group)
    }

    return group
  }

  update (name, value) {
    let ambient = this.ambientLight
    let hemi = this.hemiLight

    switch (name) {
      case 'light.ambient.visible':
        if (ambient) ambient.visible = Boolean(value)
        break
      case 'light.ambient.intensity':
        value = Number(value)
        if (ambient) ambient.intensity = value
        break
      case 'light.ambient.color':
        value = value.replace('#', '0x')
        if (ambient) ambient.color.setHex(value)
        break
      case 'light.hemi.visible':
        if (hemi) hemi.visible = Boolean(value)
        break
      case 'light.hemi.intensity':
        value = Number(value)
        if (hemi) hemi.intensity = value
        break
      case 'light.hemi.color':
        value = value.replace('#', '0x')
        if (hemi) hemi.color.setHex(value)
        break
      case 'light.hemi.groundcolor':
        value = value.replace('#', '0x')
        if (hemi) hemi.groundColor.setHex(value)
        break
      case 'light.hemi.position.x':
        value = Number(value)
        if (hemi) hemi.position.x = value
        break
      case 'light.hemi.position.y':
        value = Number(value)
        if (hemi) hemi.position.y = value
        break
      case 'light.hemi.position.z':
        value = Number(value)
        if (hemi) hemi.position.z = value
        break
    }
  }
}
