/* global pino */
import * as THREE from 'three'

// Class that creates and updates the main animation component

export default class Animation {
  constructor () {
    this.mixer = null
    this.animations = []
    this.prevtime = -1
    this.playing = false
    this.active = null
    this.activeName = null
  }

  get activeClip () {
    // Make the first animation in the list active
    if (this.active !== null) return this.active.getClip()
    return null
  }

  makeFirstAnimationActive () {
    if (this.animations.length > 0) {
      this.makeActive(this.animations[0].name)
    }
  }

  clear () {
    pino.log('[animation] clear')
    this.stop()
    this.animations = []
  }

  getByName (name) {
    return this.animations.find(row => row.name === name)
  }

  init (scene, animations) {
    this.mixer = new THREE.AnimationMixer(scene)
    if (animations !== undefined) this.animations = animations
    if (this.active === null && this.animations.length > 0) this.makeFirstAnimationActive()
  }

  isReady () {
    return (this.mixer !== null && this.mixer !== undefined)
  }

  update (delta) {
    if (this.mixer) this.mixer.update(delta)
  }

  toggleOrReplace (name) {
    if (this.isReady()) {
      this.makeActive(name)
      this.toggle()
    }
  }

  makeActive (name) {
    if (this.activeName !== name) {
      this.reset()
      let animation = this.getByName(name)
      if (animation !== undefined) {
        this.active = this.mixer.clipAction(animation)
        this.active.clampWhenFinished = true
        // this.active.setLoop(THREE.LoopOnce, 1)
        this.activeName = name
      }
    }
  }

  reset () {
    this.stop()
    this.active = null
    this.activeName = null
  }

  toggle () {
    pino.log('[animation] toggle play state')
    if (this.playing) {
      this.stop()
    } else {
      this.play()
    }
  }

  stop () {
    pino.info('Stopping animation')
    pino.log('[animation] stop')
    if (this.mixer !== null) this.mixer.stopAllAction()
    this.playing = false
  }

  pause () {
    pino.info('Pausing animation')
    if (this.active !== null) {
      this.active.paused = true
      this.playing = false
    }
  }

  play () {
    if (this.active !== null) {
      pino.info('Starting animation : ', this.activeName)
      this.active.play()
      this.playing = true
    }
  }

  time () {
    if (this.isReady()) {
      return this.mixer.time
    }

    return 0
  }

  scrubAnimation (name, time) {
    if (this.isReady()) {
      pino.log('[animation] scrubbing to time ', time)
      this.makeActive(name)
      this.mixer.setTime(time)
    }
  }
}
