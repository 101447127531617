export default class Logger {
  constructor () {
    this.messages = []
    this.errorMessages = []
    this.recentLimit = 8

    this.loading = null
  }

  add (event) {
    if (event.messages !== undefined) {
      let message = event.messages[0]

      if (message.loading === true) {
        if (message.pc !== undefined) {
          this.loading = message.pc

          if (this.loading >= 100) {
            setTimeout(() => {
              this.loading = null
            }, 2000)
          }
        }
      } else if (message.error === true) {
        this.errorMessages.push(event)
      } else {
        this.messages.push(event)
      }
    }
  }

  clear () {
    this.messages = []
    this.errorMessages = []
  }

  get loadingPc () {
    return (this.loading !== null ? this.loading : 0)
  }

  get isLoading () {
    return this.loading !== null
  }

  get history () {
    return this.messages
  }

  get recentHistory () {
    return this.messages.slice(Math.max(this.messages.length - this.recentLimit, 0))
  }
}
