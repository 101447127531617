
export default class SequenceLayer {
  constructor (id, name, order) {
    this.id = id
    this.name = name
    this._order = order

    this.enabled = true
    this.locked = false
  }

  toJson() {
    
  }

  set order (i) {
    this._order = i
  }

  get order () {
    return this._order
  }
}