import * as THREE from 'three'
import defaultScreenMat from '../materials/defaultScreenMat.js'

export default class DefaultScene {
  constructor (scene) {
    this.scene = scene
  }

  setup () {
    let group = new THREE.Group()
    group.name = 'Demo Objects'

    group.add(this.makeFloor())
    // group.add(this.makeDemoScreen('demo-16x9', new THREE.Vector3(0, 3, -2)))
    // group.add(this.makeScaleSilouette())
    this.scene.add(group)
  }

  makeScaleSilouette () {
    const geometry = new THREE.PlaneGeometry(1, 2)
    const texture = new THREE.TextureLoader().load('/static/textures/man-silouette.webp')
    const material = new THREE.MeshStandardMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      map: texture
    })

    const scaleSilouette = new THREE.Mesh(geometry, material)
    scaleSilouette.name = 'demo-silouette'
    scaleSilouette.position.y = 1
    return scaleSilouette
  }

  makeFloor () {
    const size = 100
    var divisions = 10

    var gridHelper = new THREE.GridHelper(size, divisions)
    gridHelper.name = 'demo-floor'

    return gridHelper
  }

  makeDemoScreen (name, position) {
    const width = 16
    const height = 9
    const planeGeometry = new THREE.PlaneGeometry(width, height)
    const planeMaterial = defaultScreenMat(name, { width: width, height: height })

    let plane = new THREE.Mesh(planeGeometry, planeMaterial)
    plane.name = name
    plane.receiveShadow = true
    plane.position.copy(position)
    plane.scale.set(0.5, 0.5, 0.5)
    return plane
  }
}
