import { EventEmitter } from 'events'
import { MathUtils } from '../utils/math.js'

function Bookmark (data) {
  this.name = 'New Bookmark'
  this.type = 'PrevizBookmark'
  this.id = MathUtils.generateUUID()

  this.parse(data)
}

Bookmark.prototype = Object.assign({

  constructor: Bookmark,

  isBookmark: true,

  parse (data) {
    // Todo
  },

  export () {
    const output = {}
    return output
  }
})

export { Bookmark }
