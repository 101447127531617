/* global pino,requestAnimationFrame,CustomEvent */
// Global imports -
import * as THREE from 'three'

// Components
import Renderer from './components/renderer'
import LoadingBar from './components/loadingbar'
import Logger from './components/logger'
import Camera from './components/camera'
import Controls from './components/controls'
import Sequence from './components/sequence'

// Helpers
// import Stats from './helpers/stats'
import Light from './helpers/light'
// import EnvScene from './helpers/envscene'

// Model
import Exporter from './model/exporter'

// Managers
// import Interaction from './managers/interaction'
import Gui from './managers/gui'

// data
import Config from './../data/config'

// Setup global logging + stores
const logger = new Logger()
window.pino = require('pino')({
  browser: {
    asObject: true,
    transmit: {
      level: 'trace',
      send: function (level, logEvent) {
        logger.add(logEvent)
      }
    }
  }
})

export default class Main {
  constructor (container) {
    logger.clear()
    this.container = container
    this.runsInEditorMode = false
    this.clock = new THREE.Clock()
    this.delta = 0
    this.deltaInterval = 1 / Config.maxFps // 15 fps
    this.resizeTimeout = null
    this.fatLines = []

    // Main scene creation
    this.scene = new THREE.Scene()
    this.scene.name = 'Nodes'
    this.scene._lastChange = Date.now()
    if (window.devicePixelRatio) { Config.dpr = window.devicePixelRatio }

    this.loadingBar = new LoadingBar(container)
    this.renderer = new Renderer(this.scene, container)

    // Helpers
    // this.stats = new Stats(container, this.renderer)
    this.light = new Light(this.scene)

    // Components instantiations
    this.camera = new Camera(this.renderer.threeRenderer)
    this.controls = new Controls(this.camera.threeCamera, container, this.renderer.threeRenderer)
    this.gui = new Gui(this, logger)

    // this.renderer.camera = this.camera.threeCamera
    this.renderer.setupPostProcessing(this.camera.threeCamera)
    // Instantiate core world class
    this.sequence = null

    this._dirtyPlayer = false
    this._dirtyAnimation = false
    this._dirtyControls = false
    this.dirty = false

    pino.log('Starting up.')
    // Start render which does not wait for model fully loaded
    this.render()
    this.requestRenderIfNotRequested()
    this.attachListeners()
  }

  setReady () {
    pino.info('Setting viewer ready.')

    this.gui.activateDefaultBookmark()

    this.render()
    // // Emit an event so anything waiting for the viewer to be ready so interaction can continue
    window.dispatchEvent(new CustomEvent('previz-viewer-ready'))
  }

  setPlaybackResources (resources) {
    this.gui.addPlaybackResources(resources)
  }

  setHasChanges () {
    this.gui.setHasChanges()
  }

  loadWorld (world, resources) {
    let settings = {} // todo
    if(world.settings !== undefined) settings = world.settings
    this.gui.loadSettings(settings)

    if (world.objects !== undefined && world.objects.length > 0) {

      world.objects.forEach(obj => {
        if (obj.type == 'previz_link') {
          resources.forEach((resource) => {
            if (resource.type == 'scene') {
              obj = { "src": resource.url }
              this.importIntoSequence(obj)
              this.gui.loadScene(obj, resources)
            }
          })
        }
      })
    } else {
      this.gui.loadFallbackScene(null, resources)
    }
  }

  loadScene (scene, resources) {
    this.gui.loadScene(scene, resources)
    // this.sequence = new Sequence(this, [], this.gui.texture.resources)
  }

  loadSequence (sequence, resources) {
    this.gui.clearScene()
    this.setPlaybackResources(resources)

    if (sequence.world && sequence.world.objects) {
      this.gui.loadWorld(sequence.world, resources)
    }

    this.sequence = new Sequence(this, sequence, this.gui.texture.resources)

    // URgh. Gross hack
    // This is needed to allow for exporting the data again, when we have hardcoded content.
    // : ( remove this asap
    if (sequence.world && sequence.world.objects) {
      sequence.world.objects.forEach(obj => {
        sequence.world.objects.forEach(obj => {
          this.sequence.timeline.importIntoWorld(obj)
        })
      })
    }

    // End hack
  }

  remapResourceIdToNewValue (originalValue, updatedValue) {
    // Remaps a resource id from one value to another, without reloading the underlying item
    // Used for demo scene handling where we copy over items from the shared location to a user's drive
    // The items are used initially from the share location while the copy takes place in the background
    this.gui.remapResourceIdToNewValue(originalValue, updatedValue)
  }

  importIntoSequence (obj) {
    if (this.sequence) this.sequence.timeline.importIntoWorld(obj)
    this.gui.loadScene(obj)
  }

  unloadSequence () {
    if (this.sequence !== null) this.sequence.timeline.setUnloaded()
    this.sequence = null
    this.gui.revertToBaseScene()
  }

  startRendering () {
    if (this.dirtyPlayer || this.dirtyAnimation || this.dirtyControls) requestAnimationFrame(this.startRendering.bind(this))
    this.delta = this.clock.getDelta()
    this.render()
  }

  requestRenderIfNotRequested () {
    this.resizeFatLines()
    if (this.dirty !== true && this.dirtyPlayer !== true && this.dirtyAnimation !== true && this.dirtyControls !== true) {
      this.dirty = true
      requestAnimationFrame(this.renderWhenDirty.bind(this))
    }
  }

  resizeFatLines () {
    if (this.fatLines) {
      this.fatLines.map((line) => {
        line.traverse(c => {
          if (c.material && c.material.resolution) {
            this.renderer.getSize(c.material.resolution)
            c.material.resolution.multiplyScalar(window.devicePixelRatio)
          }
        })
      })
    }

  }

  renderWhenDirty () {
    this.dirty = false
    this.render()
  }

  render () {
    // this.stats.start()
    // Call render function and pass in created scene and camera

    this.resizeFatLines()

    const hasControlsUpdated = this.controls.cameraControls.update(this.delta)
    this.dirtyControls = hasControlsUpdated

    this.renderer.render(this.scene, this.camera.threeCamera)
    if (this.sequence !== null) {
      // Tick the player and timline
      this.sequence.player.tick(this.delta)
      this.sequence.timeline.update(this.sequence.player.currentTime)
    }

    this.gui.tick(this.delta)
    // this.stats.end()
    if (this.loadingBar.busy) this.loadingBar.endBusy()
  }

  toggleEditorMode () {
    this.runsInEditorMode = !this.runsInEditorMode
  }

  turnEditorModeOn () {
    this.runsInEditorMode = true
  }

  exportScene (callback) {
    this.exporter = new Exporter()
    this.exporter.gui = this.gui
    this.exporter.exportScene(this.scene, callback)
  }

  exportSceneResources (callback) {
    this.exporter = new Exporter()
    this.exporter.gui = this.gui
    this.exporter.exportSceneResources(this.scene, callback)
  }

  exportSceneSettings (callback) {
    this.exporter = new Exporter()
    this.exporter.gui = this.gui
    this.exporter.exportSceneSettings(this.scene, callback)
  }

  exportPreviz (callback) {
    this.exporter = new Exporter()

    this.exporter.exportPreviz(this.sequence.timeline, callback)
    this.sequence.timeline.clearChanges()
  }

  exportSequence (callback) {
    this.exporter = new Exporter()


    this.exporter.exportSequence(this.sequence.timeline, callback)
    this.sequence.timeline.clearChanges()
  }

  get dirtyAnimation () {
    return this._dirtyAnimation
  }

  set dirtyAnimation (val) {
    if (val !== this._dirtyAnimation) {
      this._dirtyAnimation = val

      if (this._dirtyAnimation === true) {
        // Request playback loop start
        this.startRendering()
      }
    }
  }

  get dirtyControls () {
    return this._dirtyControls
  }

  set dirtyControls (val) {
    if (val !== this._dirtyControls) {
      this._dirtyControls = val

      if (this._dirtyControls === true) {
        // Request playback loop start
        this.startRendering()
      }
    }
  }

  get dirtyPlayer () {
    return this._dirtyPlayer
  }

  set dirtyPlayer (val) {
    if (val !== this._dirtyPlayer) {
      this._dirtyPlayer = val

      if (this._dirtyPlayer === true) {
        // Request playback loop start
        this.startRendering()
      }
    }
  }

  attachListeners () {
    // Attach camera control listeners
    this.controls.emitter.on('control', () => {
      this.dirtyControls = true
    })

    this.controls.emitter.on('controlupdate', () => {
      this.dirtyControls = true
    })

    this.controls.emitter.on('controlstart', () => {
      this.dirtyControls = true
    })

    this.controls.emitter.on('controlwake', () => {
      this.dirtyControls = true
    })

    // Attach transform control listerners

    this.controls.emitter.on('transformchange', () => {
      this.dirtyControls = true
    })

    this.controls.emitter.on('transformdraggingchanged', (event) => {
      this.controls.cameraControls.enabled = !event.value
      this.dirtyControls = true
    })

    this.controls.emitter.on('transformobjectchange', (event) => {
      this.gui.updateOutlineOnMeshTransform()
    })
    // End control listeners

    document.addEventListener('DOMContentLoaded', () => {
      this.renderer.updateSize()
      this.requestRenderIfNotRequested()
    }, false)
    window.addEventListener('resize', () => {
      this.updateSizeDelayed()
    }, false)
  }

  updateSizeDelayed () {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      let size = this.renderer.updateSize()
      let aspect = size.width / size.height
      this.camera.updateAspect(aspect)
      this.requestRenderIfNotRequested()
    }, 25)
  }
}
