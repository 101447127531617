import SequenceLayer from '../../models/SequenceLayer'
import SequenceModule from '../../models/SequenceModule'

// Main sequence class
export default class Timeline {
  constructor(player, resources) {
    this._player = player
    this._resources = resources
    this._gui = null
    this._helper = null
    this._ready = false
    this._loaded = false

    this.id = null
    this.scene_id = null
    this._duration = 100
    this._active = []
    this._waiting = []
    this._modules = []
    this._layers = []
    this._world = []

    this.prevtime = -1 // Not set to 0 because we do want to trigger an update for the 0 time, the first time it runs
    this.next = 0
    this.stepTimeIncrement = 1 / 30 // 30fps
    this.jumpTimeIncrement = 5 // 5 seconds
    this._seek = false
    this._dirty = false
    this._hasChanges = false
    this._lastChange = null

    this._blocked = 0
    this._resources.emitter.on('blocked', (id) => {
      this._blocked++
    })

    this._resources.emitter.on('refresh', () => {
      this.refresh()
    })

    this._resources.emitter.on('loaded', (id) => {
      this.setDirty()
    })

    this._resources.emitter.on('repainted', (id) => {
      this.setDirtyMaterialUsingResource(id)
    })

    this._resources.emitter.on('unblocked', (id) => {
      this._blocked--

      if (this._blocked < 1) {
        this._blocked = 0
      }
    })
  }

  setDirty() {
    if (this._gui) this._gui.setDirty()
  }

  setDirtyMaterialUsingResource(id) {
    this.active.forEach((mod) => {
      if (mod.resource !== undefined && mod.resource.id === id) {
        mod.resource.texture.needsUpdate = true
      }
    })
  }

  setHasChanges(src) {
    if (this._loaded) {
      this.hasChanges = true
      this.lastChange = Date.now()
    }
  }

  setDirtyPlayer() {
    if (this._gui) this._gui.setDirtyPlayer()
  }

  unsetDirtyPlayer() {
    if (this._gui) this._gui.unsetDirtyPlayer()
  }

  attachGui(gui) {
    this._gui = gui
    this._helper = gui.meshHelper

    this._gui.emitter.on('refresh', () => {
      if (this._loaded) {
        this.refresh()
      }
    })
  }

  get helper() {
    return this._helper
  }

  export() {
    let ret = {}

    ret.settings = {}
    ret.layers = []
    ret.scene_id = this.scene_id

    // ret.scene = this.exportScene()
    ret.world = this.exportWorld()
    ret.settings = this.exportSettings()

    this._layers.forEach((layer) => {
      ret.layers.push(this.exportLayer(layer))
    })

    return {
      id: this.id,
      data: ret
    }
  }

  exportSettings() {
    let ret = {}
    ret.duration = this._duration

    return ret
  }

  importIntoWorld(obj) {
    this._world.push(obj)
    this.setHasChanges('world')
  }

  exportWorld() {
    let ret = {}

    ret.objects = this._world
    // ret.settings = { something: 'one', another: 'two' }

    return ret
  }

  exportLayer(layer) {
    let data = {}

    data.id = layer.id
    data.name = layer.name
    data.order = layer.order
    data.enabled = layer.enabled
    data.locked = layer.locked

    data.modules = []
    let mods = this._modules.filter((row) => row.layer.id === layer.id)
    mods.forEach((mod) => {
      data.modules.push(mod.toJson())
    })

    return data
  }

  load(data) {
    let settings = {}
    let layers = []
    let sceneId = null
    let id = null
    if (data.settings !== undefined) settings = data.settings
    if (data.layers !== undefined) layers = data.layers
    if (data.id !== undefined) id = data.id
    if (data.scene_id !== undefined) sceneId = data.scene_id

    if (settings.duration !== undefined) {
      this._duration = settings.duration
    }

    layers.forEach((layer) => {
      this.addLayer(layer)
    })

    if (layers.length < 1) {
      this.addLayer({
        name: 'Layer 1'
      })
      this.addLayer({
        name: 'Layer 2'
      })
      this.addLayer({
        name: 'Layer 3'
      })
    }

    this.id = id
    this.scene_id = sceneId
    this._loaded = true
    this.setReady()
  }

  addLayer(data) {
    let id = data.id !== undefined ? data.id : generateUuid()
    let order = data.order !== undefined ? data.order : this._layers.length + 1

    let layer = new SequenceLayer(id, data.name, order)

    if (data.enabled !== undefined) {
      layer.enabled = data.enabled
    }

    if (data.locked !== undefined) {
      layer.locked = data.locked
    }

    let existingIndex = this._layers.findIndex((row) => row.id === layer.id)
    if (existingIndex > -1) {
      this._layers[existingIndex] = layer
    } else {
      this._layers.push(layer)
    }

    if (data.modules !== undefined) {
      data.modules.forEach((row) => {
        this.addModule(row, layer)
      })
    }

    this._dirty = true
    this.setDirty()
    this.setHasChanges('add_layer')
    return layer
  }

  addModule(data, layer) {
    let mod = new SequenceModule(data, layer, { count: this._modules.length })

    mod.resource = this._resources.getResource(data.resource_id)
    let existingIndex = this._modules.findIndex((row) => row.id === mod.id)
    if (existingIndex > -1) {
      this._modules[existingIndex] = mod
    } else {
      this._modules.push(mod)
    }

    this._dirty = true
    this.setDirty()
    this.setHasChanges('add_module')
    return mod
  }

  updateModuleAssetWithFile(mod, file) {
    let type = 'image'
    if (file.type.startsWith('video/')) type = 'video'

    let data = {
      file: file,
      type: type,
      name: file.name,
      settings: mod.settings
    }
    let items = this._resources.load(data)
    const resource = items[0]

    if (resource !== undefined) {
      mod.resource = resource
    }

    this._dirty = true

    this.setDirty()
    this.compile()
    this.setHasChanges('update_module_asset')
  }

  updateModuleAsset(mod, asset) {
    // First check if we have this asset already in the resources list
    let resource = this._resources.getResource(asset.id)

    if (resource === undefined) {
      let data = {
        id: asset.id,
        url: asset.url,
        type: asset.type,
        name: asset.name,
        settings: mod.settings
      }
      this._resources.load([data])

      resource = this._resources.getResource(asset.id)
    }

    if (resource !== undefined) {
      mod.resource = resource
    }

    this._dirty = true

    this.setDirty()
    this.compile()
    this.setHasChanges('update_module_asset')
  }

  compile() {
    this._layers.sort(layerSort)

    // Loop through and assign explicit order values on this sorted array
    // This fixes the possible issue where two or more layers share an order value
    let i = 0
    this._layers.forEach((layer) => {
      layer.order = i
      i++
    })

    this._modules.sort(startSort)
  }

  deleteLayer(layer) {
    let index = this._layers.findIndex((row) => row.id === layer.id)
    if (index > -1) this._layers.splice(index, 1)

    let mods = this._modules.filter((row) => row.layer.id === layer.id)
    mods.forEach((mod) => {
      this.deleteModule(mod)
    })
  }

  updateLayer(layer, props) {
    let needsDirty = false

    Object.keys(props).forEach(function (key) {
      layer[key] = props[key]
      if (key === 'disabled') needsDirty = true
      if (key === 'enabled') needsDirty = true
    })

    if (needsDirty === true) {
      this._dirty = true
      this.setDirty()
      this.compile()
    }
    this.setHasChanges('update_layer')
  }

  reorderLayers(order) {
    order.forEach((id, index) => {
      let layer = this.layers.find((layer) => layer.id === id)
      if (layer !== undefined) layer._order = index
    })

    this.compile()
    this.refresh()
    this.setHasChanges('reorder_layers')
  }

  refresh() {
    let t = this.prevtime

    this.reset()
    this._seek = true
    this.update(t)
  }

  reset(seek) {
    let mod
    if (seek === undefined) seek = false

    while (this._active.length) {
      mod = this._active.pop()

      this.endModule(mod)
    }

    this.next = 0
    this.prevtime = -1
    this._dirty = false
    this._seek = seek
    this.setDirty()
    this.compile()
  }

  async resetBlankMeshes() {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    await delay(10)
    this._player.currentTime = 0.1

    await delay(10)
    this._player.currentTime = 0
  }

  update(time) {
    if (!this._ready) return

    // Don't do anything if the time hasn't changed since last time
    // ie. we're paused
    let hasWaiting = this._waiting.length > 0
    if (this.prevtime === time && this._dirty === false && !hasWaiting) {
      return
    }

    // If the time has jumped back trigger a reset so we can recalculate properly
    if (this.prevtime > time || this._dirty === true) {
      this.reset(true)
    }

    this._waiting = []

    let activeModule

    // remove from active
    let i = 0

    while (this._active[i]) {
      activeModule = this._active[i]

      if (activeModule.start_at > time || activeModule.end_at < time) {
        this._active.splice(i, 1)
        this.endModule(activeModule)

        continue
      }

      i++
    }

    // Add to active
    while (this._modules[this.next]) {
      activeModule = this._modules[this.next]
      if (activeModule.active === true) break
      if (activeModule.start_at > time) break
      if (activeModule.layer.enabled !== true) break

      if (activeModule.end_at > time) {
        if (this.startModule(activeModule)) {
          this._active.push(activeModule)
        } else {
          this._waiting.push(activeModule)
        }
      }

      this.next++
    }

    // Render

    this._active.sort(layerSort)

    this._active.forEach((active) => {
      activeModule = active
      this.updateModule(activeModule)
    })

    this.prevtime = time

    if (time > this._duration) {
      this._player.pause()
    }

    // Unmark seek if set
    if (this._seek) {
      this.setDirty()
    }
    this._seek = false
    // BUG
    // Setting dirty here is the root cause of a number of bugs
    // We use the same delta timer internally for animation, control and player timings
    // which is a problem. they need to be independant
    // If we set dirty here any movement on either animation or control also seeks playback
    // this.setDirty()
  }

  togglePlay() {
    if (this._player.isPlaying) this.pause()
    else this.play()
  }

  play() {
    this.setDirtyPlayer()
    this.playActiveModules()
    this._player.play()
  }

  pause() {
    this.unsetDirtyPlayer()
    this.pauseActiveModules()
    this._player.pause()
  }

  setUnloaded() {
    this._loaded = false
    this.pause()
  }

  setReady() {
    this._ready = true
    this._player.stopAndJumpToStart()
    this.setDirty()
  }

  stepTimeRight() {
    let value = this.currentTime + this.stepTimeIncrement
    this.jumpToTime(value)
  }

  stepTimeLeft() {
    let value = this.currentTime - this.stepTimeIncrement
    this.jumpToTime(value)
  }

  jumpTimeRight() {
    let value = this.currentTime + this.jumpTimeIncrement
    this.jumpToTime(value)
  }

  jumpTimeLeft() {
    let value = this.currentTime - this.jumpTimeIncrement
    this.jumpToTime(value)
  }

  jumpToTime(value) {
    if (value < 0) value = 0
    this.setDirty()
    this._seek = true
    this._player.jumpToTime(value)
  }

  jumpToStart() {
    this.setDirty()
    this._seek = true
    this._player.jumpToStart()
  }

  increasePlaybackRate() {
    this._player.increasePlaybackRate()
    this.syncMediaPlaybackRate()
  }

  decreasePlaybackRate() {
    this._player.decreasePlaybackRate()
    this.syncMediaPlaybackRate()
  }

  clearChanges() {
    this.hasChanges = false
    this.lastChange = null
  }

  get hasChanges() {
    return this._hasChanges
  }

  set hasChanges(val) {
    this._hasChanges = val
  }

  get lastChange() {
    return this._lastChange
  }

  set lastChange(val) {
    this._lastChange = val
  }

  get resources() {
    return this._resources
  }

  get isBlocked() {
    return this._blocked > 0
  }

  get isPlaying() {
    return this._player.isPlaying
  }

  get isReady() {
    return this._ready
  }

  get layers() {
    return this._layers
  }

  set layers(value) {
    this._layers = value
  }

  get modules() {
    return this._modules
  }

  set modules(value) {
    this._modules = value
  }

  get currentTime() {
    return this._player.currentTime
  }

  get playbackRate() {
    return this._player.playbackRate
  }

  get progress() {
    if (this.prevtime > this._duration) return 1
    return Number.parseFloat(this.prevtime / this._duration).toFixed(4)
  }

  get progressPc() {
    return Number.parseFloat(this.progress * 100).toFixed(2)
  }

  get currentSeconds() {
    return this.prevtime.toFixed()
  }

  get currentTimecode() {
    return secondsToHHMMSS(this.prevtime.toFixed())
  }

  get duration() {
    if (!Number.isInteger(this._duration)) return 100
    return this._duration
  }

  set duration(val) {
    val = parseInt(val)
    let valFloor = Math.floor(val)

    if (valFloor <= this.durationMinimum) valFloor = this.durationMinimum
    if (valFloor >= this.durationMaxiumum) valFloor = this.durationMaxiumum

    this._duration = valFloor

    if (this.currentSeconds >= valFloor) this.jumpToStart()
    this.setHasChanges('duration_updated')
  }

  get durationFormatted() {
    return secondsToHHMMSS(this._duration)
  }

  get durationMinimum() {
    return 1
  }

  get durationMaxiumum() {
    return 3600
  }

  get active() {
    return this._active
  }

  /* Module Methods */

  editModuleSetting(mod, key, value) {
    mod.setSetting(key, value)

    this.refresh()
    this.setHasChanges('edit_module_settings')
  }

  editModuleTargets(mod, targets) {
    let arr1 = mod.target
    if (
      arr1.length === targets.length &&
      arr1.sort().every(function (value, index) {
        return value === targets.sort()[index]
      })
    ) {
      return // nothing changed
    }

    // Unset and then set new
    if (mod.active) {
      this.endModule(mod)
    }
    mod.target = targets
    this.refresh()
    this.setHasChanges('edit_module_targets')
  }

  editModuleName(mod, name) {
    mod.name = name
    this.refresh()
    this.setHasChanges('edit_module_name')
  }

  editModuleStartAndEndTimes(mod, startAt, endAt) {
    mod.start_at = startAt
    mod.end_at = endAt
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleStartTime(mod, startAt) {
    mod.start_at = startAt
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleEndTime(mod, endAt) {
    if (mod.start_at <= endAt) return
    mod.end_at = endAt
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleEndTimeFrame(mod, endAtFrame) {
    if (mod.start_at_frame <= endAtFrame) return
    mod.end_at_frame = endAtFrame
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleDurationFrame(mod, durationFrame) {
    if (durationFrame < 0) return
    mod.durationFrame = durationFrame
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleStartTimeFrame(mod, startAtFrame) {
    mod.start_at_frame = startAtFrame
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleDuration(mod, duration) {
    if (duration < 0) return
    mod.duration = duration
    this.refresh()
    this.setHasChanges('edit_module_times')
  }

  editModuleMove(mod, startAt, duration, layer) {
    mod.layer = layer
    mod.start_at = startAt
    mod.duration = duration
    this.refresh()
    this.setHasChanges('edit_module_move')
  }

  startModule(mod) {
    // if we don't have a mesh helper available there's not much we can actually do
    if (this._helper === null) {
      console.warn('[timeline] no meshhelper available for module start')
      return false
    }

    mod.active = false
    if (mod.resource !== undefined && mod.resource.texture !== undefined) {
      if (mod.resource.texture === null) {
        console.warn('[timeline] texture is null on resource!')
      } else {
        this._helper.setTargetsToTexture(
          mod.target,
          mod.resource.texture,
          mod.layer.order,
          mod.settings
        )
        mod.active = true
        if (this.isPlaying) {
          this.playModule(mod)
        }

        return true
      }
    }

    console.warn(
      '[timeline] start module failed. no resource, or resource texture available'
    )
    return false
  }

  endModule(mod) {
    if (this._helper === null) return
    this._helper.unsetTargetsFromTexture(mod.target, mod.layer.order)
    mod.active = false

    this.pauseModule(mod)
  }

  updateModule(mod) {
    if (mod.active !== true) {
      this.startModule(mod)
    }

    if (this._seek) {
      this.seekModule(mod)
    }
  }

  seekModule(mod) {
    if (
      mod.resource !== undefined &&
      mod.resource.type === 'video' &&
      mod.resource.domElement !== undefined
    ) {
      let duration = mod.resource.domElement.duration
      let t = this._player.currentTime - mod.start_at

      // While the underlying video is still loading, duration returns NaN
      if (!isNaN(duration)) {
        t = t % duration
      }

      mod.resource.seek(t)

      this.setDirty()
    }
  }

  pauseModule(mod) {
    if (mod.resource !== undefined) {
      mod.resource.pause()
    }
  }

  playModule(mod) {
    if (mod.resource !== undefined) {
      mod.resource.play()

      if (mod.resource.domElement !== undefined) {
        mod.resource.domElement.playbackRate = this.playbackRate
      }
    }
  }

  deleteModule(mod) {
    this.endModule(mod)

    let index = this._modules.findIndex((row) => row.id === mod.id)
    if (index > -1) {
      this._modules.splice(index, 1)
    }

    this.setDirty()
    this.compile()
    this.refresh()
    this.setHasChanges('delete_module')
  }

  /* End Single module actions */

  syncMediaPlaybackRate() {
    let rate = this.playbackRate
    let mod = null

    this._active.forEach((active) => {
      mod = active

      if (
        mod.resource !== undefined &&
        mod.resource.type === 'video' &&
        mod.resource.domElement !== undefined
      ) {
        mod.resource.domElement.playbackRate = rate
      }
    })
  }

  pauseActiveModules() {
    let mod = null

    this._active.forEach((active) => {
      mod = active

      this.pauseModule(mod)
    })
  }

  playActiveModules() {
    let mod = null

    this._active.forEach((active) => {
      mod = active

      this.playModule(mod)
    })
  }

  get buffered() {
    // Get a layered alignment
    // Modules are already ordered
    // We need to create discrete blocks of time

    if (this.modules.length < 1) return []

    let blocks = []
    let starts = []
    let ends = []
    this.modules.forEach((mod) => {
      starts.push(mod.start_at)
      ends.push(mod.end_at)
    })

    // First module doesn't start at a zero time.
    // give an auto buffer block for 0 > first block
    if (starts[0] > 0) {
      blocks.push({
        start_at: 0,
        end_at: starts[0]
      })
    }

    // Now loop and figure out the states
    let nextmod = null
    this.modules.forEach((mod, index) => {
      if (this.modules[index + 1] !== undefined) {
        nextmod = this.modules[index + 1]
      }

      if (nextmod !== null) {
        if (mod.end_at < nextmod.start_at) {
          // This module ends before the next module starts
          // Add an empty block for this
          blocks.push({
            start_at: mod.end_at,
            end_at: nextmod.start_at
          })
        }
      } else {
        if (mod.end_at < this.duration) {
          blocks.push({
            start_at: mod.end_at,
            end_at: this.duration
          })
        }
      }

      nextmod = null
    })

    blocks.sort(startSort)

    // As a utility - calc pc values to make it easier for the viewer
    blocks.forEach((block) => {
      block.start_at_pc = Number.parseFloat(
        (block.start_at / this.duration) * 100
      ).toFixed(2)
      block.duration_pc = Number.parseFloat(
        ((block.end_at - block.start_at) / this.duration) * 100
      ).toFixed(2)
      // _duration
      // Number.parseFloat(this.progress * 100).toFixed(2)
      // block.start_at_pc =
    })

    return blocks
  }

  attemptCreateModule(layer, x, duration) {
    // Check for other modules on layer
    // Append this module after the last module in the layer
    let othermods = this.modules.filter((row) => row.layer.id === layer.id)
    if (othermods.length > 0) {
      othermods.sort(startSort)

      let lastmod = othermods.pop()
      if (lastmod.end_at > x) x = lastmod.end_at
    }

    // Check for upper end bounding
    // Increase length of sequence to fit if nessecary
    let data = {
      start_at: x,
      end_at: x + duration
    }
    return this.addModule(data, layer)
  }

  attemptModulePlace(mod, layer, x) {}

  attemptModuleResize(mod, layer, x, adjustmode) {
    // Calc the maximum allowed move bounds
    let min = 0
    let max = this.duration
    let valid = false
    let start = mod.start_at
    let end = mod.end_at

    let updatedEnd = end
    let updatedStart = start

    if (adjustmode === 'end') {
      updatedEnd = end + x
      min = start
    } else {
      updatedStart = start + x
      max = end
    }

    let closestMax = max
    let closestMin = min

    let othermods = this.modules.filter(
      (row) => row.layer.id === layer.id && row.id !== mod.id
    )
    if (othermods.length > 0) {
      othermods.forEach((other) => {
        let a0 = other.start_at
        let a1 = other.end_at

        if (adjustmode === 'end') {
          if (a0 < closestMax && a1 >= updatedEnd) {
            closestMax = a0
          }
        } else {
          if (a1 > closestMin && a0 <= updatedStart) {
            closestMin = a1
          }
        }
      })
    }

    if (adjustmode === 'end') {
      if (updatedEnd >= closestMin && updatedEnd <= closestMax) {
        valid = true
      }
    } else {
      if (updatedStart >= closestMin && updatedStart <= closestMax) {
        valid = true
      }
    }

    if (valid) {
      this.editModuleStartAndEndTimes(mod, updatedStart, updatedEnd)
    }
  }

  attemptModuleMove(mod, layer, x, movemode) {
    // Attempts to move a module to a specific layer and start time
    // This respects other layer items and will reject a move if
    // there is overlap
    // We will extend this later to handle any snap-to behaviours
    if (x < 0) {
      x = 0
    }

    let valid = true
    let mode = 'place'
    if (movemode !== undefined) {
      mode = movemode
    }

    let newstart = x
    let duration = mod.duration
    let newend = x + mod.duration

    // 1. Get the other modules on this layer
    let othermods = this.modules.filter(
      (row) => row.layer.id === layer.id && row.id !== mod.id
    )
    if (othermods.length < 1) valid = true
    // No modules on this layer
    else {
      let overlapLeft = 0
      let overlapRight = 0

      let b0 = newstart
      let b1 = newend
      let a0 = null
      let a1 = null

      othermods.forEach((other) => {
        if (other.id !== mod.id) {
          a0 = other.start_at
          a1 = other.end_at

          // No overlap
          // if(
          //   ( a0 < b0 && a1 < b0 && a0 < b1 && a1 < b1) ||
          //   ( a0 > b0 && a1 > b0 && a0 > b1 && a1 > b1)
          // ) {
          //   // This is valid leaving this here for reference
          // }

          if (a0 < b0 && a0 < b1 && a1 > b0 && a1 < b1) {
            console.warn('Timeline Module - move - overlap on left edge')
            overlapLeft = a1 - b0
            valid = false
          }

          if (a0 > b0 && a0 < b1 && a1 > b0 && a1 > b1) {
            console.warn('Timeline Module - move - overlap on right edge')
            overlapRight = b1 - a0
            valid = false
          }

          if (a0 < b0 && a0 < b1 && a1 > b1 && a1 > b0) {
            console.warn(
              'Timeline Module - move - full module overlap. Target is bounding'
            )
            valid = false
          }

          if (a0 > b0 && a0 < b1 && a1 > b0 && a1 < b1) {
            console.warn(
              'Timeline Module - move - full module overlap. Source is bounding'
            )
            valid = false
          }

          if (!valid) {
            let data = {}
            data.a = {
              start: a0,
              end: a1
            }
            data.b = {
              start: b0,
              end: b1
            }
            console.table(data)
          }
        }
      })

      if (!valid) {
        // Not a valid move
        // If we have a overlap Left || overlap Right try the move adjusted
        let shift = 0
        if (overlapLeft > 0 && mode === 'place') {
          shift = x + overlapLeft
          return this.attemptModuleMove(mod, layer, shift, 'left')
        }

        if (overlapRight > 0 && (mode === 'left' || mode === 'place')) {
          shift = x - overlapRight
          return this.attemptModuleMove(mod, layer, shift, 'right')
        }
      }
    }

    if (valid) {
      this.editModuleMove(mod, x, duration, layer)
    }

    return valid
  }
}

function secondsToHHMMSS(value) {
  if (value < 0) value = 0
  var secondsVal = parseInt(value, 10) // don't forget the second param
  var hours = Math.floor(secondsVal / 3600)
  var minutes = Math.floor((secondsVal - hours * 3600) / 60)
  var seconds = secondsVal - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (hours > 0) return hours + ':' + minutes + ':' + seconds
  return minutes + ':' + seconds
}

function startSort(a, b) {
  return a.start_at === b.start_at ? layerSort(a, b) : a.start_at - b.start_at
}

function layerSort(a, b) {
  return a.order - b.order
}

function generateUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, (c) =>
      (
        c ^
        (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
    .toUpperCase()
}
