import { EventEmitter } from 'events'

export default class BaseResource {
  constructor (object) {
    this.id = null
    this.name = null
    this.url = null
    this.settings = null
    this.file = null
    this._original = object

    if (object.id !== undefined) this.id = object.id
    else this.id = generateUuid()

    if (object.file !== undefined) this.file = object.file
    if (object.name !== undefined) this.name = object.name
    if (object.url !== undefined) this.url = object.url
    if (object.settings !== undefined) this.settings = object.settings

    this.type = 'resource'
    this._state = 'pending'
    this.texture = null
    this.promise = null
    this.emitter = new EventEmitter()
  }

  get bufferState () {
    return 'BUFFER'
  }

  get state () {
    return this._state
  }

  get duration () {
    return null
  }

  toJson() {
    return this._original
  }

  play () {
    // nothing

  }

  pause () {
    // nothing
  }

  seek () {
  }
}



function generateUuid () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  ).toUpperCase()
}