import * as THREE from "three";
import { EventEmitter } from "events";

export default class HighlightHelper {
  constructor(container, scene, camera) {
    this.raycaster = new THREE.Raycaster();
    this.emitter = new EventEmitter();
    this.highlightObject = null;
    this.highlightObjectSavedColor = 0;

    this.mouse = new THREE.Vector2();
    this.selectedObjects = [];

    this.container = container;
    this.scene = scene;
    this.camera = camera;

    this.active = false;

    this.selectedObject = null;

    this.attach();
  }

  attach() {
    window.addEventListener("message", (event) => {
      let driveElement = document.getElementById("drive");
      if (driveElement) {
        if (event.data.event === "dragging") {
          let drive = driveElement.getBoundingClientRect();
          let res = {
            clientX: event.data.x + drive.x,
            clientY: event.data.y + drive.y,
            assetId: event.data.assetId,
          };
          this.onMove(res);
        } else if (event.data.event === "drag_end") {
          let drive = driveElement.getBoundingClientRect();
          let res = {
            clientX: event.data.x + drive.x,
            clientY: event.data.y + drive.y,
            assetId: event.data.assetId,
          };
          this.onDrop(res);
        }
      }
    });
  }

  dettach() {
    window.removeEventListener("message");
  }

  onMove(event) {
    const rect = this.container.getBoundingClientRect();
    const rectHit = this.checkIfMouseIsInsideRect(event, rect);
    let clearSelected = true;
    if (rectHit) {
      this.updateMouseCoordinates(event, rect);

      this.raycaster.setFromCamera(this.mouse, this.camera.threeCamera);
      const intersectedObjects = this.raycaster.intersectObjects(
        this.scene.children,
        true
      );

      if (intersectedObjects.length) {
        // pick the first object. It's the closest one
        if (intersectedObjects[0].object.type === "Mesh") {
          if (
            this.selectedObject == null ||
            intersectedObjects[0].object.uuid !== this.selectedObject.uuid
          ) {
            this.selectedObject = intersectedObjects[0].object;
            this.emitter.emit("highlightobject", intersectedObjects[0].object);
          }
          clearSelected = false;
        }
      }
    }

    if (clearSelected) {
      this.clear();
    }
  }

  updateMouseCoordinates(event, rect) {
    this.mouse.x = ((event.clientX - rect.x) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.y) / rect.height) * 2 + 1;
  }

  checkIfMouseIsInsideRect(event, rect) {
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  }

  clear() {
    if (this.selectedObject !== null) {
      this.selectedObject = null;
      this.emitter.emit("highlightobject", undefined);
    }
  }

  onDrop(event) {
    this.selectedObject = null;
    this.emitter.emit("highlightobject", undefined);
    const rect = this.container.getBoundingClientRect();
    const rectHit = this.checkIfMouseIsInsideRect(event, rect);

    if (rectHit) {
      this.updateMouseCoordinates(event, rect);

      this.raycaster.setFromCamera(this.mouse, this.camera.threeCamera);
      const intersectedObjects = this.raycaster.intersectObjects(
        this.scene.children,
        true
      );
      if (intersectedObjects.length) {
        // pick the first object. It's the closest one
        for (let i = 0; i < intersectedObjects.length; i++) {
          if (intersectedObjects[i].object.type === "Mesh") {
            window.postMessage(
              {
                event: "return_asset",
                assetId: event.assetId,
                meshName: intersectedObjects[i].object.name,
              },
              "*"
            );
            break;
          }
        }
      }
    }
  }
}
