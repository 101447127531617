import * as THREE from 'three'
// Promise polyfill for IE
import { Promise } from 'es6-promise'

import Helpers from '../../utils/helpers'
import Config from '../../data/config'

// This class preloads all textures in the imageFiles array in the Config via ES6 Promises.
// Once all textures are done loading the model itself will be loaded after the Promise .then() callback.
// Using promises to preload textures prevents issues when applying textures to materials
// before the textures have loaded.
export default class Texture {
  constructor (main) {
    // Prop that will contain all loaded textures
    this.main = main
    this.textures = {}

    this.scene = null
    this.compositions = []
    this.imageFiles = []
    this.videoFiles = []
  }

  addResources (resources) {
    resources.forEach(resource => {
      switch (resource.type) {
        case 'scene':
          this.scene = resource // There is only one scene supported at the moment
          break
        case 'image':
          this.imageFiles.push(resource)
          break
        case 'video':
          this.videoFiles.push(resource)
          break
        case 'composition':
          this.compositions.push(resource)
      }
    })
  }

  load () {
    const loader = new THREE.TextureLoader()
    const maxAnisotropy = Config.maxAnisotropy
    const imageFiles = Config.texture.imageFiles
    const promiseArray = []
    loader.setPath(Config.texture.path)

    imageFiles.forEach(imageFile => {
      promiseArray.push(new Promise((resolve, reject) => {
        loader.load(
          imageFile.image,
          texture => {
            texture.anisotropy = maxAnisotropy
            texture.encoding = THREE.sRGBEncoding

            const modelOBJ = {}
            modelOBJ[imageFile.name] = texture
            if (modelOBJ[imageFile.name] instanceof THREE.Texture) { resolve(modelOBJ) }
          },
          Helpers.logProgress(),
          xhr => reject(new Error(xhr + 'An error occurred loading while loading ' + imageFile.image))
        )
      }))
    })

    return Promise.all(promiseArray).then(textures => {
      // Set the textures prop object to have name be the resolved texture
      for (let i = 0; i < textures.length; i++) {
        this.textures[Object.keys(textures[i])[0]] = textures[i][Object.keys(textures[i])[0]]
      }
    }, reason => console.log(reason))
  }

  get resources () {
    return [this.scene, ...this.imageFiles, ...this.videoFiles, ...this.compositions]
  }
}
