import * as THREE from 'three'

// USe this class as a helper to set up some default materials
export default class Material {
  constructor (color = null) {
    this.basic = new THREE.MeshBasicMaterial({
      color,
      side: THREE.DoubleSide
    })

    this.standard = new THREE.MeshStandardMaterial({
      color,
      flatShading: true,
      roughness: 1,
      metalness: 0,
      side: THREE.DoubleSide
    })

    this.lambert = new THREE.MeshLambertMaterial({
      color,
      opacity: 1,
      side: 2
    })

    this.wire = new THREE.MeshBasicMaterial({
      color,
      wireframe: true
    })

    this.normal = new THREE.MeshNormalMaterial()
  }
}
