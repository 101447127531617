import { MathUtils } from '../utils/math.js'

const fps = 60
const fallbackStartAtFrame = 0 
const fallbackEndAtFrame = 10 * fps 


export default class SequenceModule {
  constructor (data, layer, extra) {
    this.id = (data.id !== undefined ? data.id : MathUtils.generateUUID())

    let name = (data.name !== undefined ? data.name : generateModuleName(extra.count))
    this._target = []
    this._resource = null
    this._name = name
    this._layer = layer
    this._start_at = data.start_at 
    this._end_at = data.end_at


    if(data.start_at_frame !== undefined) {
      this._start_at_frame = data.start_at_frame 
    } else if (data.start_at !== undefined) {
      this._start_at_frame = Math.floor(data.start_at * fps)
    } else {
      this._start_at_frame = fallbackStartAtFrame
    }


    if(data.end_at_frame !== undefined) {
      this._end_at_frame = data.end_at_frame 
    } else if(data.end_at !== undefined) {
      this._end_at_frame = Math.floor(data.end_at * fps)
    } else {
      this._end_at_frame = fallbackEndAtFrame
    }

    this._settings = {
      flipY: false
    }

    this.target = (data.target !== undefined ? data.target : [])
    if (data.settings !== undefined) this._settings = data.settings

    this.dirty = false
  }

  toJson() {
    let data = {}

    data.id = this.id
    data.resource_id = null
    data.start_at_frame = this.start_at_frame
    data.end_at_frame = this.end_at_frame
    data.settings = this.settings
    data.name = this.name

    if (this.resource !== undefined) {
      data.resource_id = this.resource.id
      data.resource = this.resource.toJson()
    }

    data.target = this.target.join('|')

    return data
  }

  get name () {
    return this._name
  }

  set name (value) {
    this._name = value
  }


  toggleTarget (key) {
    let parts = key.split(':')
    let meshname = parts[0]

    let index = this._target.findIndex((row) => {
      return row.includes(meshname + ':')
    })

    if (index > -1) {
      if (!this.hasTargetExact(key)) {
        this._target.splice(index, 1)
        this._target.push(key)
      } else {
        this._target.splice(index, 1)
      }
    } else {
      this._target.push(key)
    }
  }

  hasTargetExact (key) {
    let index = this._target.findIndex(row => row === key)
    return index > -1
  }

  get target () {
    return this._target
  }

  set target (val) {
    if (val === null || val === undefined || val === '') return

    let arr = val
    if (!Array.isArray(val)) {
      arr = val.split('|')
    }

    this._target = arr
    this.dirty = true
  }

  get resource () {
    return this._resource
  }

  set resource (res) {
    this._resource = res
    this.dirty = true
  }

  get duration () {
    return this.durationFrame / fps 
  }

  get settings () {
    return this._settings
  }

  set settings (value) {
    // This won't override existing default settings
    Object.keys(value).forEach(key => {
      this._settings[key] = value[key]
    })
  }

  setSetting (key, val) {
    this._settings[key] = val
  }

  getSetting (key) {
    if (this._settings[key] !== undefined) return this._settings[key]
    return null
  }

  // Duration
  get duration_frame () {
    return this.end_at_frame - this.start_at_frame
  }

  set duration_frame (val) {
    if (val > 0) {
      this.end_at_frame = Math.floor(this.start_at_frame + Number(val))
    }
  }

  get duration () {
    return this.duration_frame / fps 
  }

  set duration (val) {
    this.duration_frame = val * fps
  }
  // End duration 

  // Start At

  set start_at_frame (val) {
    val = Math.floor(val)
    if (val < 0) val = 0
    this._start_at_frame = val
    this.dirty = true
  }

  get start_at_frame () {
    return this._start_at_frame
  }

  get start_at () {
    return this.start_at_frame / fps
  }

  set start_at (val) {
    this.start_at_frame = val * fps
  }
  // End start at

  // End At
  set end_at_frame (val) {
    val = Math.floor(val) 
    
    if(val <= this.start_at_frame) return 

    this._end_at_frame = val
    this.dirty = true
  }

  get end_at_frame () {
    return this._end_at_frame
  }


  get end_at () {
    return this.end_at_frame / fps
  }

  set end_at (val) {
    this.end_at_frame = val * fps
  }
  // End end at

  get layer () {
    return this._layer
  }

  set layer (layer) {
    this._layer = layer
    this.dirty = true
  }
}

function generateModuleName (count) {
  return 'Module ' + count
}
