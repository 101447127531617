import * as THREE from 'three'
import BaseResource from './BaseResource'

export default class ImageResource extends BaseResource {
  constructor (object) {
    super(object)
    this.type = 'image'
  }

  load () {

    this.emitter.emit('blocked')
    return new Promise((resolve, reject) => {

      if (this.url !== null) {
        const loader = new THREE.TextureLoader()

        loader.load(
          this.url,
          texture => {
            this._state = 'loaded'
            texture.wrapS = THREE.RepeatWrapping // ClampToEdgeWrapping is the default
            texture.wrapT = THREE.RepeatWrapping
            texture.encoding = THREE.sRGBEncoding
            this.texture = texture

            this.emitter.emit('unblocked')
            this.emitter.emit('loaded')

            resolve(texture)
          }, null, // Helpers.logProgress(),
          xhr => reject(new Error(xhr + 'An error occurred loading while loading ' + this.url))
        )
      } else {
        const loader = new FileReader()
        loader.onload = function (event) {
          const image = document.createElement('img')
          image.src = event.target.result
          const texture = new THREE.Texture(image)
          texture.needsUpdate = true

          this._state = 'loaded'
          texture.wrapS = THREE.RepeatWrapping // ClampToEdgeWrapping is the default
          texture.wrapT = THREE.RepeatWrapping
          texture.encoding = THREE.sRGBEncoding
          this.texture = texture

          this.emitter.emit('unblocked')
          this.emitter.emit('loaded')

          resolve(texture)
        }.bind(this)
        loader.readAsDataURL(this.file)
      }
    })
  }

  get bufferState () {
    return 'IMAGEBUFF'
  }
}