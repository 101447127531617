import * as THREE from 'three'

/* TO DO
 - handle name update
 - initiate canvas one and clear it
 - type resolution
 - display resolution
*/

const generateTextureFromName = (name, width, height) => {
  const ctx = document.createElement('canvas')
  const g = ctx.getContext('2d')
  const texWidth = width * 5
  const texHeight = height * 5
  const fontSize = 13
  g.textAlign = 'center'
  g.font = `Bold ${fontSize}px Arial`
  const lineHeight = g.measureText('M').width // hack to approximate the lineheight for text in canvas which is otherwise quite convoluted. Efficiency can vary with the font
  g.translate(texWidth / 2, texHeight / 2 - lineHeight)
  g.fillStyle = 'white'
  g.fillText(name, 0, fontSize)
  return ctx
}

const defaultScreenMat = (name, resolution) => {
  const { width, height } = resolution
  let material = new THREE.ShaderMaterial({
    uniforms: {
      textTex: { value: new THREE.CanvasTexture(generateTextureFromName(name, width, height)) },
      resolution: { value: new THREE.Vector2(width, height) },
      gridSize: { value: 1.0 }

    },
    vertexShader: `
        varying vec2 vUv;
        uniform sampler2D textTex;
        void main() {
          vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        } 
      `,
    fragmentShader: `
      precision mediump float;  
      varying vec2 vUv;
      uniform vec2 resolution;
      
      vec2 cell (vec2 uv, vec2 resolution, float size) {
        return fract((uv - 0.5)*resolution / size);
      }
      
      float distToCell (vec2 cell) {
        return 1.0 - 2.0* max(cell.x, cell.y);
      }
      
      void main() {
        float thickness = 0.05;
        float gridSize = 2.0;
        vec2 baseCell = cell(vUv, resolution, gridSize);
        baseCell = abs(baseCell - 0.5);
        float cellLine = smoothstep(0.0, thickness, distToCell(baseCell));
        
        vec2 circleCell = cell(vUv, resolution, gridSize*3.0);
        float pixelToCenter = distance(circleCell, vec2(0.5));
        float radius = 0.5;
        float circle = abs(radius - pixelToCenter);
        float t = smoothstep(0.0, thickness/5.0, circle);
        
        vec2 subCell = cell(vUv, resolution, gridSize/6.0);
        subCell = abs(subCell - 0.5);
        float subCellLine = smoothstep(0.0, thickness*2.0, distToCell(subCell));
        
        gl_FragColor = vec4(vec3(1.0 - t*cellLine*subCellLine), 1.0);
      }
      `,
    flatShading: true,
    uniformsNeedUpdate: true,
    side: THREE.DoubleSide,
    transparent: false
  })
  return material
}

export default defaultScreenMat
